// import { lazy } from "react";
import page404 from "../pages/page404";
// const attendee = lazy(() => import("../pages/event/attendee"));
const RenderSubPage = (element, content) => {
  if (content) {
    return content;
  } else {
    switch (element.page) {
      // case "attendee":
      //   return attendee;
      default:
        return page404;
    }
  }
};

export default RenderSubPage;
