import { Image } from "@react-pdf/renderer";
import { GetIcon } from "../../../../icons";
import { dateFormat, dateTimeFormat } from "../../functions/date";
import { IconBox, Img } from "../styles";
import { food } from "../../../../images";
export function convertMinutesToHHMM(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);

  const hoursStr = hours.toString().padStart(2, "0");
  const minsStr = mins.toString().padStart(2, "0");

  return `${hoursStr}:${minsStr}`;
}
export const getValue = (attribute, itemValue, display = false, isPrint = false, onClick = () => {}) => {
  let response = "";
  switch (attribute.type) {
    case "minute":
      response = convertMinutesToHHMM(parseFloat(itemValue ?? 0));
      break;
    case "image":
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"]; // Supported image extensions
      const src = itemValue?.length > 0 ? process.env.REACT_APP_CDN + itemValue : food;
      const extension = itemValue?.length > 10 ? itemValue.split(".").pop().toLowerCase() : "jpeg";
      if (imageExtensions.includes(extension)) {
        response = isPrint ? (
          <Image
            onClick={(e) => {
              onClick({ src: e.target.src.replace("/thumbnail", "") });
              e.stopPropagation();
            }}
            style={{ width: 50, height: 50 }}
            source={src}
          />
        ) : (
          <Img
            onClick={(e) => {
              onClick({ src: e.target.src.replace("/thumbnail", "") });
              e.stopPropagation();
            }}
            src={src}
          />
        );
      } else {
        response = (
          <a
            onClick={(e) => {
              const src = itemValue ? process.env.REACT_APP_CDN + itemValue : food;
              const extension = itemValue.split(".").pop().toLowerCase();

              if (!imageExtensions.includes(extension)) {
                e.preventDefault(); // Prevent default anchor behavior
                // Create a download link
                const link = document.createElement("a");
                link.href = src;
                link.target = "_blank"; // Open in a new tab
                link.download = ""; // This will prompt the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
              }
            }}
            href={itemValue ? process.env.REACT_APP_CDN + itemValue : food}
            target={imageExtensions.includes(itemValue.split(".").pop().toLowerCase()) ? "_self" : "_blank"}
            rel="noreferrer" // Security enhancement
          >
            Download {extension}
          </a>
        );
      }
      break;
    case "datetime":
      response = dateTimeFormat(itemValue);
      break;
    case "multiSelect":
      if (attribute.apiType === "API") {
        response = itemValue.map((item) => item[attribute.showItem].toString()).join(", ");
      } else {
        response = itemValue.map((item) => attribute.selectApi.find((label) => label.id === item).value.toString()).join(", ");
      }
      break;
    case "select1":
      if (attribute.apiType === "API") {
        response = itemValue.map((item) => item[attribute.showItem].toString()).join(", ");
      } else {
        response = itemValue.map((item) => attribute.selectApi.find((label) => label.id === item).value.toString()).join(", ");
      }
      break;
    case "number":
      try {
        if (Number.isInteger(itemValue ? itemValue : 0)) {
          response = (itemValue ? itemValue : 0).toString(); // Return as whole number
        } else {
          response = (itemValue ? itemValue : 0).toFixed(2); // Format with two decimal places
        }
        response = itemValue;
      } catch (error) {
        // console.log(error);
        response = itemValue;
      }
      break;
    case "percentage":
      try {
        if (Number.isInteger(itemValue ? itemValue : 0)) {
          response = (itemValue ? itemValue : 0).toString() + "%"; // Return as whole number
        } else {
          response = (itemValue ? itemValue : 0).toFixed(2) + "%"; // Format with two decimal places
        }
      } catch (error) {
        console.log(error);
        response = itemValue;
      }
      break;
    case "date":
      response = dateFormat(itemValue);
      break;
    case "textarea":
    case "htmleditor":
      response = isPrint ? (
        itemValue
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: itemValue?.toString()?.substring(0, 200),
          }}
        ></span>
      );
      break;
    case "checkbox":
      response = <IconBox className={display && "display"}>{itemValue ? <GetIcon icon={"checked"} /> : <GetIcon icon={"Close"} />}</IconBox>;
      break;
    case "icon":
      response = <i className={`fa-light ${itemValue}`} />;
      break;
    default:
      switch (typeof itemValue) {
        case "undefined":
          response = "--";
          break;
        case "object":
          response = itemValue?.[attribute.showItem] ?? "--";
          break;
        case "boolean":
          response = itemValue.toString();
          break;
        case "string":
        case "number":
        default:
          if (attribute.type === "select" && attribute.apiType === "JSON") {
            const value = attribute.selectApi.filter((item) => item.id.toString() === itemValue?.toString()).map((filteredItem) => (response = filteredItem.value));
            response = value?.[0].toString().substring(0, 200);
          } else {
            response = itemValue?.toString().substring(0, 200);
          }
          break;
      }
  }
  return response;
};
export const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return `${age} years old`;
};
export const findChangedValues = (object, updatedObject) => {
  const changes = {};
  const logEntries = [];
  console.log(object, updatedObject);

  const setsEqual = (set1, set2) => {
    if (set1.size !== set2.size) return false;
    for (let item of set1) {
      if (!set2.has(item)) return false;
    }
    return true;
  };

  for (const key in updatedObject) {
    if (updatedObject.hasOwnProperty(key)) {
      const originalValue = object[key];
      const updatedValue = updatedObject[key];

      // Check if the value is a multi-value string (array of strings)
      if (Array.isArray(originalValue) && Array.isArray(updatedValue)) {
        const originalSet = new Set(originalValue);
        const updatedSet = new Set(updatedValue);

        // Compare sets
        if (!setsEqual(originalSet, updatedSet)) {
          const logEntry = `${key} [${Array.from(originalSet).join(", ")}]->[${Array.from(updatedSet).join(", ")}]`;
          logEntries.push(logEntry);
          changes[key] = updatedValue;
        }
      } else if (originalValue !== updatedValue) {
        // Log entry for the change
        const logEntry = `${key} ${originalValue}->${updatedValue}`;
        logEntries.push(logEntry);
        changes[key] = updatedValue;
      }
    }
  }

  // Create log message
  const logMessage = logEntries.length > 0 ? logEntries.join("\n") + " are changed" : "No changes detected";
  console.log(logMessage);
  // Return the changes and the log message
  return {
    log: logMessage,
    changedObject: changes,
  };
};
