import React from "react";
import styled from "styled-components";
const Svg = styled.svg`
  height: 1em;
`;
export const PreparingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M112 0c-8.8 0-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16c0-8.8-7.2-16-16-16zM256 352H192c-88.4 0-160-71.6-160-160H416c0 88.4-71.6 160-160 160zM32 160c-17.7 0-32 14.3-32 32c0 80.5 49.5 149.4 119.7 177.9l-7.4 16.2c-5.2-1.4-10.7-2.1-16.4-2.1c-35.3 0-64 28.7-64 64s28.7 64 64 64c29.8 0 54.9-20.4 62-48H335.9l17.6 38.6c3.7 8 13.1 11.6 21.2 7.9s11.6-13.1 7.9-21.2L328.3 369.9C398.5 341.4 448 272.5 448 192c0-17.7-14.3-32-32-32H32zM140.4 401.9l10.2-22.4c13.4 2.9 27.2 4.5 41.5 4.5h64c14.2 0 28.1-1.5 41.5-4.5L321.3 432H158c-3-11.7-9.2-22-17.6-30.1zM64 448a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM224 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16z"
    />
  </Svg>
);
export const DefaultIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
  </Svg>
);
export const DeletedIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zM331.3 235.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 329.4 308.7 212.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
  </Svg>
);
export const ArrowLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" fill="currentColor">
    <path fill="currentColor" d="M2.20425 4.9992L5.91675 8.7117L4.85625 9.7722L0.083252 4.9992L4.85625 0.226196L5.91675 1.2867L2.20425 4.9992Z" />
  </Svg>
);
export const ArrowDoubleLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
    <path fill="currentColor" d="M0.0249023 5.00057L4.33536 9.31106L5.31751 8.32897L1.98908 5.00057L5.31751 1.67215L4.33536 0.690063L0.0249023 5.00057ZM3.94842 5.00057L8.2589 9.31106L9.24106 8.32897L5.91265 5.00057L9.24106 1.67215L8.2589 0.690063L3.94842 5.00057Z" />
  </Svg>
);
export const ArrowRightIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
    <path fill="currentColor" d="M3.796 4.9992L0.0834961 1.2867L1.144 0.226196L5.917 4.9992L1.144 9.7722L0.0834961 8.7117L3.796 4.9992Z" />
  </Svg>
);
export const ArrowRightDoubleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="currentColor" className="arrow-right-double-icon">
    <path d="M9.97541 5.00057L5.66492 0.690063L4.68284 1.67215L8.01124 5.00057L4.68284 8.32897L5.66492 9.31106L9.97541 5.00057ZM6.05187 5.00057L1.74137 0.690063L0.759277 1.67215L4.0877 5.00057L0.759277 8.32897L1.74137 9.31106L6.05187 5.00057Z" fill="currentColor" />
  </Svg>
);
export const BackIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16" fill="none">
    <path fill="currentColor" d="M3.371 5.24998L5.273 7.15198L4.2125 8.21247L0.5 4.49998L4.2125 0.787476L5.273 1.84798L3.371 3.74998H8.75C10.3413 3.74998 11.8674 4.38212 12.9926 5.50733C14.1179 6.63255 14.75 8.15868 14.75 9.74998C14.75 11.3413 14.1179 12.8674 12.9926 13.9926C11.8674 15.1178 10.3413 15.75 8.75 15.75H2V14.25H8.75C9.94347 14.25 11.0881 13.7759 11.932 12.932C12.7759 12.088 13.25 10.9434 13.25 9.74998C13.25 8.5565 12.7759 7.41191 11.932 6.56799C11.0881 5.72408 9.94347 5.24998 8.75 5.24998H3.371Z" />
  </Svg>
);
export const PenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
    <path fill="currentColor" d="M2.77717 7.10093H7.98516V7.98686H0.0117188V6.10735L4.39711 1.72196L6.27618 3.60192L2.77673 7.10093H2.77717ZM5.02302 1.09604L5.963 0.156062C6.04607 0.0730187 6.15872 0.0263672 6.27618 0.0263672C6.39364 0.0263672 6.50629 0.0730187 6.58936 0.156062L7.84252 1.40922C7.92556 1.49229 7.97222 1.60494 7.97222 1.7224C7.97222 1.83986 7.92556 1.95251 7.84252 2.03558L6.90254 2.97512L5.02347 1.09604H5.02302Z" />
  </Svg>
);

export const GetCustomIcon = ({ icon }) => {
  const trimmedIcon = icon && typeof icon === "string" ? icon.trim() : "";

  switch (trimmedIcon) {
    case "pen":
      return <PenIcon />;
    case "back":
      return <BackIcon />;
    case "ArrowRightDoubleIcon":
      return <ArrowRightDoubleIcon />;
    case "arrowRight":
      return <ArrowRightIcon />;
    case "ArrowLeft":
      return <ArrowLeft />;
    case "ArrowDoubleLeft":
      return <ArrowDoubleLeft />;
    case "deleted":
      return <DeletedIcon />;
    case "preparation":
      return <PreparingIcon />;
    default:
      // console.log("missing icon", icon);
      return <DefaultIcon />;
  }
};
