import React from "react";
import styled from "styled-components";
import { GetCustomIcon } from "../components/project/icons";
const Svg = styled.svg`
  height: 1em;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &.rotate-icon {
    animation: rotate 2s linear infinite; /* Adjust the duration and easing as necessary */
  }
`;
export const HomeIcon = () => (
  <Svg className="svg-inline--fa fa-house-blank" aria-hidden="true" focusable="false" data-prefix="far" data-icon="house-blank" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
    <path fill="currentColor" d="M303.5 5.7c-9-7.6-22.1-7.6-31.1 0l-264 224c-10.1 8.6-11.3 23.7-2.8 33.8s23.7 11.3 33.8 2.8L64 245.5V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V245.5l24.5 20.8c10.1 8.6 25.3 7.3 33.8-2.8s7.3-25.3-2.8-33.8l-264-224zM464 204.8V432c0 17.7-14.3 32-32 32H144c-17.7 0-32-14.3-32-32V204.8L288 55.5 464 204.8z"></path>
  </Svg>
);
export const DwonlaodIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M272 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4L139.3 228.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l128 128c6.2 6.2 16.4 6.2 22.6 0l128-128c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V16zM140.1 320H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H371.9l-32 32H448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />{" "}
  </Svg>
);
export const PatientIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M478.3 8.8L505.9 64H624c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-6.1 0-11.6-3.4-14.3-8.8L465.6 55 414.9 181.9c-2.3 5.9-7.9 9.8-14.2 10s-12.2-3.2-15-8.8L342.1 96H208c-8.8 0-16-7.2-16-16s7.2-16 16-16H352c6.1 0 11.6 3.4 14.3 8.8L398.4 137 449.1 10.1c2.3-5.9 7.9-9.8 14.2-10s12.2 3.2 15 8.8zM304 160h34.3l16 32H304c-8.8 0-16 7.2-16 16V352H608V272c0-44.2-35.8-80-80-80H445.3l12.8-32H528c61.9 0 112 50.1 112 112v96 64 64c0 8.8-7.2 16-16 16s-16-7.2-16-16V448H32v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368 80c0-8.8 7.2-16 16-16s16 7.2 16 16V352H256V208c0-26.5 21.5-48 48-48zM608 416V384H272 32v32H608zM192 240a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM64 240a80 80 0 1 1 160 0A80 80 0 1 1 64 240z"
    />
  </Svg>
);
export const FaqIcon = () => (
  <Svg className="svg-inline--fa fa-message-question" aria-hidden="true" focusable="false" data-prefix="far" data-icon="message-question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M208 416c0-26.5-21.5-48-48-48H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H448c8.8 0 16 7.2 16 16V352c0 8.8-7.2 16-16 16H309.3c-10.4 0-20.5 3.4-28.8 9.6L208 432V416zm-.2 76.2l.2-.2 101.3-76H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h48 48v48 4 .3 6.4V496c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L202.7 496l5.1-3.8zM169.8 117.3l-.4 1.2c-4.4 12.5 2.1 26.2 14.6 30.6s26.2-2.1 30.6-14.6l.4-1.2c1.1-3.2 4.2-5.3 7.5-5.3h58.3c8.4 0 15.1 6.8 15.1 15.1c0 5.4-2.9 10.4-7.6 13.1l-44.3 25.4c-7.5 4.3-12.1 12.2-12.1 20.8V216c0 13.3 10.7 24 24 24c13.1 0 23.8-10.5 24-23.6l32.3-18.5c19.6-11.3 31.7-32.2 31.7-54.8c0-34.9-28.3-63.1-63.1-63.1H222.6c-23.7 0-44.8 14.9-52.8 37.3zM288 304a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
    ></path>
  </Svg>
);
export const ContactIcon = () => (
  <Svg className="svg-inline--fa fa-messages" aria-hidden="true" focusable="false" data-prefix="far" data-icon="messages" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M48 72c0-13.3 10.7-24 24-24H344c13.3 0 24 10.7 24 24V248c0 13.3-10.7 24-24 24H216c-4.7 0-9.4 1.4-13.3 4L144 315.2V296c0-13.3-10.7-24-24-24H72c-13.3 0-24-10.7-24-24V72zM72 0C32.2 0 0 32.2 0 72V248c0 39.8 32.2 72 72 72H96v40c0 8.9 4.9 17 12.7 21.2s17.3 3.7 24.6-1.2l90-60H344c39.8 0 72-32.2 72-72V72c0-39.8-32.2-72-72-72H72zM256 376c0 39.8 32.2 72 72 72h88.7l90 60c7.4 4.9 16.8 5.4 24.6 1.2S544 496.9 544 488V448h24c39.8 0 72-32.2 72-72V200c0-39.8-32.2-72-72-72H448v48H568c13.3 0 24 10.7 24 24V376c0 13.3-10.7 24-24 24H520c-13.3 0-24 10.7-24 24v19.2L437.3 404c-3.9-2.6-8.6-4-13.3-4H328c-13.3 0-24-10.7-24-24V352H256v24z"
    ></path>
  </Svg>
);
export const ContactLargeIcon = () => (
  <Svg className="svg-inline--fa fa-messages" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="messages" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M576 128H448v32h128c17.64 0 32 14.36 32 32v192c0 17.64-14.36 32-32 32h-64v49.57L424.4 416H320c-17.64 0-32-14.36-32-32v-32l-32-.0039v31.98c0 35.25 28.75 63.1 63.1 63.1L416 448l108.9 61.63C527.1 511.3 529.6 512 532 512C538.3 512 544 507.1 544 500v-52.03h32c35.25 0 64-28.75 64-63.1V191.1C640 156.7 611.3 128 576 128zM416 256V63.1C416 28.75 387.3 0 352 0H64C28.75 0 0 28.75 0 63.1v192C0 291.3 28.75 320 64 320l32 .0106V372C96 379.1 101.8 384 108 384c2.375 0 4.875-.75 7.125-2.375L224 320l128-.075C387.3 319.9 416 291.3 416 256zM215.6 288L128 337.6V288H64C46.36 288 32 273.6 32 256V64c0-17.64 14.36-32 32-32h288c17.64 0 32 14.36 32 32v192c0 17.64-14.36 32-32 32H215.6z"
    ></path>
  </Svg>
);
export const CloseIcon = () => (
  <Svg className="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
    <path fill="currentColor" d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z"></path>
  </Svg>
);
export const LogoutIcon = () => (
  <Svg className="svg-inline--fa fa-arrow-right-from-bracket" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right-from-bracket" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M505 273c9.4-9.4 9.4-24.6 0-33.9L377 111c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l87 87L184 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l246.1 0-87 87c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273zM168 80c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l80 0z"></path>
  </Svg>
);
export const DateIcon = () => (
  <Svg className="svg-inline--fa fa-calendar" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="calendar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
    <path fill="currentColor" d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z"></path>
  </Svg>
);
export const FileIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M448 160v32h32V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384h80c19.6 0 37.3-11.9 44.6-30.2l64-160c5.9-14.8 4.1-31.5-4.8-44.7S543.9 224 528 224H128c-19.6 0-37.3 11.9-44.6 30.2L32 382.8V96c0-17.7 14.3-32 32-32H181.5c8.5 0 16.6 3.4 22.6 9.4l22.6-22.6L204.1 73.4l26.5 26.5c18 18 42.4 28.1 67.9 28.1H416c17.7 0 32 14.3 32 32zM384 448H64c-5.3 0-10.3-2.6-13.2-7s-3.6-10-1.6-14.9l64-160c2.4-6.1 8.3-10.1 14.9-10.1H528c5.3 0 10.3 2.6 13.2 7s3.6 10 1.6 14.9l-64 160C476.4 444 470.5 448 464 448H384z"
    />
  </Svg>
);
export const OpneIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM235.3 379.3l112-112c6.2-6.2 6.2-16.4 0-22.6l-112-112c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L313.4 256 212.7 356.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0z" />
  </Svg>
);
export const ExternalIcon1 = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z" />
  </Svg>
);
export const OpenIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM235.3 379.3l112-112c6.2-6.2 6.2-16.4 0-22.6l-112-112c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L313.4 256 212.7 356.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0z" />
  </Svg>
);
export const CarIcon = () => (
  <Svg className="svg-inline--fa fa-car" aria-hidden="true" focusable="false" data-prefix="far" data-icon="car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M127.7 106.8L103.4 176H408.6l-24.2-69.2c-5.6-16-20.8-26.8-37.8-26.8H165.4c-17 0-32.1 10.7-37.8 26.8zm-79.6 82L82.3 90.9C94.7 55.6 128 32 165.4 32H346.6c37.4 0 70.7 23.6 83.1 58.9l34.3 97.9C492.6 205.4 512 236.4 512 272v80 48 56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400H48v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400 352 272c0-35.6 19.3-66.6 48.1-83.2zM416 224H96c-26.5 0-48 21.5-48 48v80H464V272c0-26.5-21.5-48-48-48zM112 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm256 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path>
  </Svg>
);
export const DurationIcon = () => (
  <Svg className="svg-inline--fa fa-timer" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="timer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 202.7 16.28 153.2 44.15 112.2C49.12 104.9 59.07 103 66.37 108C73.68 112.1 75.58 122.9 70.61 130.2C46.24 166.1 32 209.4 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 137.7 388.2 40.77 272 32.56V112C272 120.8 264.8 128 256 128C247.2 128 240 120.8 240 112V16C240 7.164 247.2 0 256 0C397.4 0 512 114.6 512 256V256zM267.3 244.7C273.6 250.9 273.6 261.1 267.3 267.3C261.1 273.6 250.9 273.6 244.7 267.3L148.7 171.3C142.4 165.1 142.4 154.9 148.7 148.7C154.9 142.4 165.1 142.4 171.3 148.7L267.3 244.7z"
    ></path>
  </Svg>
);
export const QuestionIcon = () => (
  <Svg className="svg-inline--fa fa-circle-question" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM240 344c-13.25 0-24 10.75-24 24s10.75 24 24 24s24-10.75 24-24S253.3 344 240 344zM285.6 128H226.4C189.8 128 160 157.8 160 194.4V204c0 8.844 7.156 16 16 16S192 212.8 192 204V194.4C192 175.4 207.4 160 226.4 160h59.25C304.6 160 320 175.4 320 194.4c0 12.48-6.781 24-17.06 29.72L242.5 254.3C231.1 260.7 224 272.7 224 285.8V304C224 312.8 231.2 320 240 320S256 312.8 256 304V285.8c0-1.453 .7813-2.797 1.438-3.172l60.41-30.22C338.9 240.7 352 218.5 352 194.4C352 157.8 322.2 128 285.6 128z"
    ></path>
  </Svg>
);
export const DefaultIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
  </Svg>
);
export const LocationIcon = () => (
  <Svg className="svg-inline--fa fa-location-dot" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 240C218.5 240 240 218.5 240 192C240 165.5 218.5 144 192 144C165.5 144 144 165.5 144 192C144 218.5 165.5 240 192 240zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 32C103.6 32 32 103.6 32 192C32 207.6 37.43 229 48.56 255.4C59.47 281.3 74.8 309.4 92.14 337.5C126.2 392.8 166.6 445.7 192 477.6C217.4 445.7 257.8 392.8 291.9 337.5C309.2 309.4 324.5 281.3 335.4 255.4C346.6 229 352 207.6 352 192C352 103.6 280.4 32 192 32z"
    ></path>
  </Svg>
);
export const TickIcon = () => (
  <Svg className="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
    <path fill="currentColor" d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"></path>
  </Svg>
);
export const UploadIcon = () => (
  <Svg className="svg-inline--fa fa-arrow-right-from-bracket" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M308.7 164.7C314.9 158.4 325.1 158.4 331.3 164.7L411.3 244.7C417.6 250.9 417.6 261.1 411.3 267.3C405.1 273.6 394.9 273.6 388.7 267.3L336 214.6V368C336 376.8 328.8 384 320 384C311.2 384 304 376.8 304 368V214.6L251.3 267.3C245.1 273.6 234.9 273.6 228.7 267.3C222.4 261.1 222.4 250.9 228.7 244.7L308.7 164.7zM272 32C331.5 32 384.1 61.55 416 106.8C430.5 99.87 446.8 96 464 96C525.9 96 576 146.1 576 208C576 218.7 574.5 228.1 571.7 238.8C612.3 260.2 640 302.9 640 352C640 422.7 582.7 480 512 480H144C64.47 480 0 415.5 0 336C0 273.2 40.15 219.9 96.17 200.1C100.3 106.6 177.4 32 272 32zM272 64C194.6 64 131.5 125 128.1 201.5C127.6 214.6 119.1 225.1 106.8 230.3C63.18 245.7 32 287.2 32 336C32 397.9 82.14 448 144 448H512C565 448 608 405 608 352C608 315.2 587.3 283.2 556.8 267.1C543.4 259.1 536.8 244.5 540.9 229.1C542.9 223 544 215.7 544 208C544 163.8 508.2 128 464 128C451.7 128 440.1 130.8 429.7 135.7C415.7 142.4 398.8 137.9 389.8 125.2C363.7 88.12 320.7 64 272 64V64z"
    />
  </Svg>
);
export const ExternalIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z" />
  </Svg>
);
export const DownIcon = ({ className }) => (
  <Svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M199 401c9.4 9.4 24.6 9.4 33.9 0L425 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L41 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L199 401z" />
  </Svg>
);
export const UpIcon = ({ className }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path d="M244.7 116.7c6.2-6.2 16.4-6.2 22.6 0l192 192c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L256 150.6 75.3 331.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l192-192z" />{" "}
  </Svg>
);
export const NextIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path fill="currentColor" d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z" />
  </Svg>
);
export const PreviousIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
  </Svg>
);
export const DeleteIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M147.2 0H160 288h12.8L308 10.7 354.2 80h13.7H416h32v48H412.4L384 512H64L35.6 128H0V80H32 80.1 93.8L140 10.7 147.2 0zm4.4 80h145L275.2 48H172.8L151.5 80zM83.7 128l24.9 336H339.4l24.9-336H83.7z" />
  </Svg>
);
export const AddIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M248 72V48H200V72 232H40 16v48H40 200V440v24h48V440 280H408h24V232H408 248V72z" />
  </Svg>
);
export const EditIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M454.6 45.3l12.1 12.1c12.5 12.5 12.5 32.8 0 45.3L440 129.4 382.6 72l26.7-26.7c12.5-12.5 32.8-12.5 45.3 0zM189 265.6l171-171L417.4 152l-171 171c-4.2 4.2-9.6 7.2-15.4 8.6l-65.6 15.1L180.5 281c1.3-5.8 4.3-11.2 8.6-15.4zm197.7-243L166.4 243c-8.5 8.5-14.4 19.2-17.1 30.9l-20.9 90.6c-1.2 5.4 .4 11 4.3 14.9s9.5 5.5 14.9 4.3l90.6-20.9c11.7-2.7 22.4-8.6 30.9-17.1L489.4 125.3c25-25 25-65.5 0-90.5L477.3 22.6c-25-25-65.5-25-90.5 0zM80 64C35.8 64 0 99.8 0 144V432c0 44.2 35.8 80 80 80H368c44.2 0 80-35.8 80-80V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"
    />
  </Svg>
);
export const FilterIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M27.6 64C12.4 64 0 76.4 0 91.6c0 6.7 2.4 13.2 6.8 18.2L112 230V360c0 10 4.7 19.5 12.7 25.5l76.2 57.5c4.2 3.2 9.4 4.9 14.7 4.9c13.5 0 24.4-10.9 24.4-24.4V230L345.2 109.8c4.4-5 6.8-11.5 6.8-18.2C352 76.4 339.6 64 324.4 64H27.6zM140 213.5L37.3 96H314.7L212 213.5c-2.6 2.9-4 6.7-4 10.5V408.3L144 360V224c0-3.9-1.4-7.6-4-10.5zM336 400c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H336zM320 256c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H336c-8.8 0-16 7.2-16 16zM400 80c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H400z"
    />
  </Svg>
);
export const CallIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M375.8 275.2c-16.4-7-35.4-2.4-46.7 11.4l-33.2 40.6c-46-26.7-84.4-65.1-111.1-111.1L225.3 183c13.8-11.3 18.5-30.3 11.4-46.7l-48-112C181.2 6.7 162.3-3.1 143.6 .9l-112 24C13.2 28.8 0 45.1 0 64v0C0 300.7 183.5 494.5 416 510.9c4.5 .3 9.1 .6 13.7 .8c0 0 0 0 0 0c0 0 0 0 .1 0c6.1 .2 12.1 .4 18.3 .4l0 0c18.9 0 35.2-13.2 39.1-31.6l24-112c4-18.7-5.8-37.6-23.4-45.1l-112-48zM447.7 480C218.1 479.8 32 293.7 32 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0-3.8 2.6-7 6.3-7.8l112-24c3.7-.8 7.5 1.2 9 4.7l48 112c1.4 3.3 .5 7.1-2.3 9.3l-40.6 33.2c-12.1 9.9-15.3 27.2-7.4 40.8c29.5 50.9 71.9 93.3 122.7 122.7c13.6 7.9 30.9 4.7 40.8-7.4l33.2-40.6c2.3-2.8 6.1-3.7 9.3-2.3l112 48c3.5 1.5 5.5 5.3 4.7 9l-24 112c-.8 3.7-4.1 6.3-7.8 6.3c-.1 0-.2 0-.3 0z"
    />
  </Svg>
);
// export const NoteIcon = () => (
//   <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
//     <path fill="currentColor" d="M128 96c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32V256H368c-26.5 0-48 21.5-48 48v80H160c-17.7 0-32-14.3-32-32V96zM446.2 288c-1.6 4.5-4.2 8.7-7.6 12.1l-74.5 74.5c-3.4 3.4-7.6 6-12.1 7.6V304c0-8.8 7.2-16 16-16h78.2zM96 96V352c0 35.3 28.7 64 64 64H341.5c17 0 33.3-6.7 45.3-18.7l74.5-74.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM320 496c0-8.8-7.2-16-16-16H128c-53 0-96-43-96-96l0-240c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H304c8.8 0 16-7.2 16-16z" />
//   </Svg>
// );
export const NotificationIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M208 16c0-8.8 7.2-16 16-16s16 7.2 16 16V32.8c80.9 8 144 76.2 144 159.2v29.1c0 43.7 17.4 85.6 48.3 116.6l2.8 2.8c8.3 8.3 13 19.6 13 31.3c0 24.5-19.8 44.3-44.3 44.3H44.3C19.8 416 0 396.2 0 371.7c0-11.7 4.7-23 13-31.3l2.8-2.8C46.6 306.7 64 264.8 64 221.1V192c0-83 63.1-151.2 144-159.2V16zm16 48C153.3 64 96 121.3 96 192v29.1c0 52.2-20.7 102.3-57.7 139.2L35.6 363c-2.3 2.3-3.6 5.4-3.6 8.7c0 6.8 5.5 12.3 12.3 12.3H403.7c6.8 0 12.3-5.5 12.3-12.3c0-3.3-1.3-6.4-3.6-8.7l-2.8-2.8c-36.9-36.9-57.7-87-57.7-139.2V192c0-70.7-57.3-128-128-128zM193.8 458.7c4.4 12.4 16.3 21.3 30.2 21.3s25.8-8.9 30.2-21.3c2.9-8.3 12.1-12.7 20.4-9.8s12.7 12.1 9.8 20.4C275.6 494.2 251.9 512 224 512s-51.6-17.8-60.4-42.7c-2.9-8.3 1.4-17.5 9.8-20.4s17.5 1.4 20.4 9.8z"
    />
  </Svg>
);
export const SearchIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z" />
  </Svg>
);

export const MenuIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M0 80c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16z" />
  </Svg>
);
export const UserIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
  </Svg>
);

export const MealSettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 25.8 4.3 44.4 11.7 59.2c7.2 14.3 16.7 23.9 24.6 31.7l.4 .4c8.1 8.1 14.3 14.4 19 23.8c4.6 9.2 8.3 22.7 8.3 44.8c0 8.8 7.2 16 16 16s16-7.2 16-16c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-.4-.4c-8.1-8.1-14.3-14.4-19-23.8C131.7 51.6 128 38.2 128 16zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 25.8 4.3 44.4 11.7 59.2c7.2 14.3 16.7 23.9 24.6 31.7l0 0 0 0 0 0 .4 .4c8.1 8.1 14.3 14.4 19 23.8c4.6 9.2 8.3 22.7 8.3 44.8c0 8.8 7.2 16 16 16s16-7.2 16-16c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-.4-.4c-8.1-8.1-14.3-14.4-19-23.8C227.7 51.6 224 38.2 224 16zM32 256c-8.8 0-16 7.2-16 16s7.2 16 16 16H67.8c5.9 82.9 56.5 152.7 127.3 186.4c7.6 21.9 28.4 37.6 52.9 37.6H392c24.5 0 45.3-15.7 52.9-37.6C515.7 440.7 566.3 370.9 572.2 288H608c8.8 0 16-7.2 16-16s-7.2-16-16-16H570.4c-6.6-18.6-24.3-32-45.3-32H114.9c-21 0-38.7 13.4-45.3 32H32zm507.4-64c-13.8-46.3-56.6-80-107.4-80s-93.6 33.7-107.4 80h34c12.3-28.3 40.5-48 73.3-48s61 19.7 73.3 48h34zM225.3 463.9c-2.8-8.1-8.7-14.7-16.5-18.4c-61.4-29.2-105-90.1-109.3-162.2l-.6-10.3c-.5-9.2 6.8-16.9 16-16.9H525.1c9.2 0 16.5 7.7 16 16.9l-.6 10.3c-4.2 72.1-47.8 133-109.3 162.2c-7.7 3.7-13.7 10.3-16.5 18.4c-3.3 9.4-12.2 16.1-22.7 16.1H248c-10.4 0-19.4-6.7-22.7-16.1z"
    />
  </Svg>
);

export const SettingsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M195 108c-13.2 5.4-25.5 12.6-36.7 21.2l-13.3 10.2-16-5.1-52-16.6L46.9 170.2l40.3 36.7 12.4 11.3-2.2 16.6c-.9 6.9-1.4 14-1.4 21.2s.5 14.3 1.4 21.2l2.2 16.6L87.2 305.1 46.9 341.8l30.2 52.4 52-16.6 16-5.1 13.3 10.2c11.2 8.6 23.5 15.8 36.7 21.2l15.5 6.4 3.6 16.4L225.8 480h60.5l11.7-53.3 3.6-16.4L317 404c13.2-5.4 25.5-12.6 36.7-21.2l13.3-10.2 16 5.1 52 16.6 30.2-52.4-40.3-36.7-12.4-11.3 2.2-16.6c.9-6.9 1.4-14 1.4-21.2s-.5-14.3-1.4-21.2l-2.2-16.6 12.4-11.3 40.3-36.7-30.2-52.4-52 16.6-16 5.1-13.3-10.2c-11.2-8.6-23.5-15.8-36.7-21.2l-15.5-6.4-3.6-16.4L286.2 32H225.8L214.1 85.3l-3.6 16.4L195 108zM200 0H312l17.2 78.4c15.8 6.5 30.6 15.1 44 25.4l76.5-24.4 56 97-59.4 54.1c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l59.4 54.1-56 97-76.5-24.4c-13.4 10.3-28.2 18.9-44 25.4L312 512H200l-17.2-78.4c-15.8-6.5-30.6-15.1-44-25.4L62.3 432.5l-56-97 59.4-54.1C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L6.3 176.5l56-97 76.5 24.4c13.4-10.3 28.2-18.9 44-25.4L200 0zM312 256a56 56 0 1 0 -112 0 56 56 0 1 0 112 0zm-144 0a88 88 0 1 1 176 0 88 88 0 1 1 -176 0z"
    />
  </Svg>
);

export const WeeklyPlannerIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H64C28.7 64 0 92.7 0 128v32 32V448c0 35.3 28.7 64 64 64H258.8c-3.1-8.8-3.7-18.4-1.4-27.8l1.1-4.2H64c-17.7 0-32-14.3-32-32V192H384h32 32V160 128c0-35.3-28.7-64-64-64H352V16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H128V16zM64 96H384c17.7 0 32 14.3 32 32v32H32V128c0-17.7 14.3-32 32-32zm48 256c-8.8 0-16 7.2-16 16s7.2 16 16 16H240c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zM96 272c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zm422.8-8l17.4 17.4c6.2 6.2 6.2 16.4 0 22.6l-24.8 24.8-40-40L496.2 264c6.2-6.2 16.4-6.2 22.6 0zM342.5 417.7L448.7 311.5l40 40L382.4 457.7c-2.1 2.1-4.6 3.5-7.4 4.2l-49 12.3 12.3-49c.7-2.8 2.2-5.4 4.2-7.4zM473.5 241.4L319.8 395.1c-6.2 6.2-10.5 13.9-12.6 22.3l-18.7 74.9c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2L382.8 493c8.4-2.1 16.1-6.5 22.3-12.6L558.8 326.6c18.7-18.7 18.7-49.1 0-67.9l-17.4-17.4c-18.7-18.7-49.1-18.7-67.9 0z"
    />
  </Svg>
);

export const BookingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M127 10.4c3.1 8.3-1.1 17.5-9.4 20.6L74.4 47.2C68.1 49.5 64 55.5 64 62.2V192c0 70.7 57.3 128 128 128s128-57.3 128-128V62.2c0-6.7-4.1-12.6-10.4-15L266.4 31c-8.3-3.1-12.5-12.3-9.4-20.6s12.3-12.5 20.6-9.4l43.2 16.2c18.7 7 31.1 24.9 31.1 44.9V192c0 83-63.1 151.2-144 159.2v.8c0 70.7 57.3 128 128 128s128-57.3 128-128V254c-27.6-7.1-48-32.2-48-62c0-35.3 28.7-64 64-64s64 28.7 64 64c0 29.8-20.4 54.9-48 62v98c0 88.4-71.6 160-160 160s-160-71.6-160-160v-.8C95.1 343.2 32 275 32 192V62.2c0-20 12.4-37.9 31.1-44.9L106.4 1c8.3-3.1 17.5 1.1 20.6 9.4zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
    />
  </Svg>
);

export const DispatchIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M240 64H342.4c12.6 0 24.1 7.4 29.2 19l34.2 77H240V64zm0 128H416V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192H208h32zm-32-32H42.1L76.3 83c5.1-11.6 16.6-19 29.2-19H208v96zM400.9 70c-10.3-23.1-33.2-38-58.5-38H105.6C80.3 32 57.4 46.9 47.1 70L5.5 163.6c-3.6 8.2-5.5 17-5.5 26V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V189.6c0-9-1.9-17.8-5.5-26L400.9 70zM331.3 251.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 345.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
  </Svg>
);

export const DeliveryManIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M336 48c0-8.8 7.2-16 16-16h40.9c20.7 0 39 13.2 45.5 32.8l5.2 15.5L500.3 52c5.2-2.6 11-4 16.9-4C532 48 544 60 544 74.9v74.3c0 14.8-12 26.9-26.9 26.9c-5.9 0-11.6-1.4-16.9-4L469 156.4 494.5 233c5.7-.7 11.6-1 17.5-1c41.5 0 79.1 16.6 106.5 43.5c6.3 6.2 6.4 16.3 .2 22.6s-16.3 6.4-22.6 .2C574.4 277.1 544.7 264 512 264c-56.5 0-104 39.1-116.7 91.8c-1.1 4.6-2 9.3-2.5 14.1c-.9 8.1-7.8 14.1-15.9 14.1H336 288 256c0 53-43 96-96 96s-96-43-96-96H32c-17.7 0-32-14.3-32-32V320c0-70.7 57.3-128 128-128h96c17.7 0 32 14.3 32 32v96c0 17.7 14.3 32 32 32h32 16 16 11.4c.3-1.3 .6-2.5 .9-3.8c12.2-50.8 50-91.6 98.9-108.2l-55-165.1c-2.2-6.5-8.3-10.9-15.2-10.9H352c-8.8 0-16-7.2-16-16zm118.7 65.5c.2 .1 .3 .1 .5 .2L512 142.1V81.9l-56.8 28.4c-.4 .2-.9 .4-1.3 .6l.9 2.6zM112 128H240c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 96H128c-53 0-96 43-96 96l0 32H69.5h35.1H215.4h17.1c-5.4-9.4-8.6-20.3-8.6-32V224zM160 448c35.3 0 64-28.7 64-64H96c0 35.3 28.7 64 64 64zm352 0a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm0-160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"
    />
  </Svg>
);

export const DieticianIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M320 128c0-5.5-.5-10.8-1.3-16H288.6c-14.9 0-29.1-5.9-39.6-16.4l-6.3-6.3C223.4 113.5 194 128 162.3 128L128 128v16c0 53 43 96 96 96s96-43 96-96V128zM228.7 52.7c6.2-6.2 16.4-6.2 22.6 0L271.6 73c4.5 4.5 10.6 7 17 7h18.6C290.6 51.3 259.5 32 224 32c-41.8 0-77.4 26.7-90.5 64h28.9c26.7 0 51.2-15 63.2-38.9c.8-1.6 1.8-3.1 3.1-4.5zM96 128C96 57.3 153.3 0 224 0s128 57.3 128 128v16c0 70.7-57.3 128-128 128s-128-57.3-128-128V128zm80 208v34.7c18.6 6.6 32 24.4 32 45.3c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V339.8C80.2 354.7 32.6 411.8 32 480H416c-.5-62.4-40.4-115.4-96-135.5v25.8c23.1 6.9 40 28.3 40 53.7v24c0 8.8-7.2 16-16 16H328c-8.8 0-16-7.2-16-16s7.2-16 16-16v-8c0-13.3-10.7-24-24-24s-24 10.7-24 24v8c8.8 0 16 7.2 16 16s-7.2 16-16 16H264c-8.8 0-16-7.2-16-16V424c0-25.4 16.9-46.8 40-53.7V337c-5.6-.7-11.4-1-17.2-1H177.2l-1.2 0zm-32 80a16 16 0 1 0 32 0 16 16 0 1 0 -32 0zM0 481.2C0 383.3 79.3 304 177.2 304h93.6C368.7 304 448 383.3 448 481.2c0 17-13.8 30.8-30.8 30.8H30.8C13.8 512 0 498.2 0 481.2z"
    />
  </Svg>
);

export const SubscribersIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M96 80a48 48 0 1 1 96 0A48 48 0 1 1 96 80zm128 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 80a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 160a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 64H378.7c54.2 0 98.4 42.5 101.2 96H160.1c2.8-53.5 47-96 101.2-96zm0-32C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352H261.3zM512 32a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 128A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm16 64c44.2 0 80 35.8 80 80c0 8.8 7.2 16 16 16s16-7.2 16-16c0-61.9-50.1-112-112-112H444c2.6 10.2 4 21 4 32h80zm-336 0c0-11 1.4-21.8 4-32H112C50.1 192 0 242.1 0 304c0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.2 35.8-80 80-80h80z"
    />
  </Svg>
);

export const UserRoleIcon = () => (
  <Svg className="svg-inline--fa fa-house-blank" aria-hidden="true" focusable="false" data-prefix="far" data-icon="house-blank" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
    <path
      fill="currentColor"
      d="M224 224a96 96 0 1 0 0-192 96 96 0 1 0 0 192zM32 480H322.2l-1 3.8c-2.4 9.5-1.8 19.3 1.4 28.2H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304h91.4c44.2 0 84.6 16.1 115.8 42.7l-22.7 22.7c-25.3-20.9-57.7-33.4-93.1-33.4H178.3c-80 0-145 64.3-146.3 144zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM582.8 264c-6.2-6.2-16.4-6.2-22.6 0l-24.8 24.8 40 40L600.2 304c6.2-6.2 6.2-16.4 0-22.6L582.8 264zM406.5 417.7c-2.1 2.1-3.5 4.6-4.2 7.4l-12.3 49 49-12.3c2.8-.7 5.4-2.2 7.4-4.2L552.7 351.4l-40-40L406.5 417.7zM537.5 241.4c18.7-18.7 49.1-18.7 67.9 0l17.4 17.4c18.7 18.7 18.7 49.1 0 67.9L469.1 480.3c-6.2 6.2-13.9 10.5-22.3 12.6l-74.9 18.7c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l18.7-74.9c2.1-8.4 6.5-16.1 12.6-22.3L537.5 241.4z"
    />
  </Svg>
);

export const UserListIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path fill="currentColor" d="M128 128a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM269.7 336c80 0 145 64.3 146.3 144H32c1.2-79.7 66.2-144 146.3-144h91.4zM224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zm457-116.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L496 281.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
  </Svg>
);

export const AimOfprogrmeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M326.3 9.8C304 3.4 280.4 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-24.4-3.4-48-9.8-70.3l-22.8 25.4c-1.1 1.2-2.1 2.3-3.3 3.4c2.5 13.5 3.8 27.3 3.8 41.5c0 123.7-100.3 224-224 224S32 379.7 32 256S132.3 32 256 32c14.2 0 28.1 1.3 41.5 3.8c1.1-1.1 2.2-2.2 3.4-3.3L326.3 9.8zM274.6 97.1c-6.1-.7-12.3-1.1-18.6-1.1C167.6 96 96 167.6 96 256s71.6 160 160 160s160-71.6 160-160c0-6.3-.4-12.5-1.1-18.6c-2.7-.2-5.4-.5-8.1-.9l-25-4.2c1.4 7.7 2.2 15.6 2.2 23.7c0 70.7-57.3 128-128 128s-128-57.3-128-128s57.3-128 128-128c8.1 0 16 .8 23.7 2.2l-4.2-25c-.5-2.7-.8-5.4-.9-8.1zm66.9 96.1l70.4 11.7c16.3 2.7 32.8-3.1 43.8-15.5L499.6 140c11.7-13.1 5.8-33.9-11-38.9L428.9 83.2 410.9 23.4c-5-16.8-25.8-22.7-38.9-11L322.6 56.3c-12.3 11-18.2 27.5-15.5 43.8l11.7 70.4-74.2 74.2c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l74.2-74.2zm27.8-27.8l51.3-51.3 46.8 14-35.6 40c-3.7 4.1-9.2 6.1-14.6 5.2l-47.9-8zm28.6-73.9l-51.3 51.3-8-47.9c-.9-5.4 1-10.9 5.2-14.6l40-35.6 14 46.8z"
    />
  </Svg>
);

export const MedicalConditionIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      fill="currentColor"
      d="M145.5 68c5.3-20.7 24.1-36 46.5-36s41.2 15.3 46.5 36c1.8 7.1 8.2 12 15.5 12h18c8.8 0 16 7.2 16 16v32H192 96V96c0-8.8 7.2-16 16-16h18c7.3 0 13.7-4.9 15.5-12zM192 0c-32.8 0-61 19.8-73.3 48H112C91.1 48 73.3 61.4 66.7 80H64C28.7 80 0 108.7 0 144V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V144c0-35.3-28.7-64-64-64h-2.7c-6.6-18.6-24.4-32-45.3-32h-6.7C253 19.8 224.8 0 192 0zM320 112c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32v16c0 17.7 14.3 32 32 32h96 96c17.7 0 32-14.3 32-32V112zM208 80a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM176 208c-17.7 0-32 14.3-32 32v32H112c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h32v32c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V368h32c17.7 0 32-14.3 32-32V304c0-17.7-14.3-32-32-32H240V240c0-17.7-14.3-32-32-32H176zm0 80V240h32v48c0 8.8 7.2 16 16 16h48v32H224c-8.8 0-16 7.2-16 16v48H176V352c0-8.8-7.2-16-16-16H112V304h48c4.2 0 8.3-1.7 11.3-4.7s4.7-7.1 4.7-11.3z"
    />
  </Svg>
);

export const ProtineCatagoryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M208 64c8.8 0 16 7.2 16 16V256 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V368 144 80c0-8.8 7.2-16 16-16h32zM128 413.3V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V272H384V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V413.3c5 1.8 10.4 2.7 16 2.7h32c26.5 0 48-21.5 48-48V272h16c8.8 0 16-7.2 16-16s-7.2-16-16-16H608V144c0-26.5-21.5-48-48-48H528c-5.6 0-11 1-16 2.7V80c0-26.5-21.5-48-48-48H432c-26.5 0-48 21.5-48 48V240H256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48V98.7C123 97 117.6 96 112 96H80c-26.5 0-48 21.5-48 48v96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H32v96c0 26.5 21.5 48 48 48h32c5.6 0 11-1 16-2.7zM512 144c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16V256 368c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V144zM480 368v64c0 8.8-7.2 16-16 16H432c-8.8 0-16-7.2-16-16V256 80c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v64V368zM128 144V368c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V256 144c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16z"
    />
  </Svg>
);

export const DayOfweekIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M128 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H64C28.7 64 0 92.7 0 128v32 32V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 160 128c0-35.3-28.7-64-64-64H352V16c0-8.8-7.2-16-16-16s-16 7.2-16 16V64H128V16zM32 192H416V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192zM64 96H384c17.7 0 32 14.3 32 32v32H32V128c0-17.7 14.3-32 32-32zm40 160h80c4.4 0 8 3.6 8 8v80c0 4.4-3.6 8-8 8H104c-4.4 0-8-3.6-8-8V264c0-4.4 3.6-8 8-8zm-40 8v80c0 22.1 17.9 40 40 40h80c22.1 0 40-17.9 40-40V264c0-22.1-17.9-40-40-40H104c-22.1 0-40 17.9-40 40z" />
  </Svg>
);

export const LikeListIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M244 130.6l-12-13.5-4.2-4.7c-26-29.2-65.3-42.8-103.8-35.8c-53.3 9.7-92 56.1-92 110.3v3.5c0 32.3 13.4 63.1 37.1 85.1L253 446.8c.8 .7 1.9 1.2 3 1.2s2.2-.4 3-1.2L443 275.5c23.6-22 37-52.8 37-85.1v-3.5c0-54.2-38.7-100.6-92-110.3c-38.5-7-77.8 6.6-103.8 35.8l-4.2 4.7-12 13.5c-3 3.4-7.4 5.4-12 5.4s-8.9-2-12-5.4zm34.9-57.1C311 48.4 352.7 37.7 393.7 45.1C462.2 57.6 512 117.3 512 186.9v3.5c0 36-13.1 70.6-36.6 97.5c-3.4 3.8-6.9 7.5-10.7 11l-184 171.3c-.8 .8-1.7 1.5-2.6 2.2c-6.3 4.9-14.1 7.5-22.1 7.5c-9.2 0-18-3.5-24.8-9.7L47.2 299c-3.8-3.5-7.3-7.2-10.7-11C13.1 261 0 226.4 0 190.4v-3.5C0 117.3 49.8 57.6 118.3 45.1c40.9-7.4 82.6 3.2 114.7 28.4c6.7 5.3 13 11.1 18.7 17.6l4.2 4.7 4.2-4.7c4.2-4.7 8.6-9.1 13.3-13.1c1.8-1.5 3.6-3 5.4-4.5z"
    />
  </Svg>
);

export const DislikeListIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M272 126.1l7.9-8.9 4.2-4.7c26-29.2 65.3-42.8 103.8-35.8c53.3 9.7 92 56.1 92 110.3v3.5c0 32.3-13.4 63.1-37 85.1L259 446.8c-.8 .7-1.9 1.2-3 1.2s-2.2-.4-3-1.2L69.1 275.5C45.4 253.5 32 222.7 32 190.4v-3.5c0-54.2 38.7-100.6 92-110.3c38.5-7 77.8 6.6 103.8 35.8l4.2 4.7 7.9 8.9V144c0 4.2 1.7 8.3 4.7 11.3l34.2 34.2-79.8 53.2c-3.7 2.5-6.2 6.4-6.9 10.9s.5 8.9 3.3 12.4l64 80c5.5 6.9 15.6 8 22.5 2.5s8-15.6 2.5-22.5l-53.1-66.4 81.5-54.3c4-2.7 6.6-7 7-11.7s-1.2-9.5-4.6-12.9L272 137.4V126.1zM393.7 45.1c-40.9-7.4-82.6 3.2-114.7 28.4c-1.8 1.4-3.6 2.9-5.4 4.5c-4.7 4.1-9.1 8.4-13.3 13.1L256 95.9l-4.2-4.7c-5.8-6.5-12-12.4-18.7-17.6C201 48.4 159.3 37.7 118.3 45.1C49.8 57.6 0 117.3 0 186.9v3.5c0 36 13.1 70.6 36.6 97.5c3.4 3.8 6.9 7.5 10.7 11l184 171.3c6.7 6.3 15.6 9.7 24.8 9.7c8 0 15.8-2.7 22.1-7.5c.9-.7 1.8-1.4 2.6-2.2L464.8 299c3.8-3.5 7.3-7.2 10.7-11C498.9 261 512 226.4 512 190.4v-3.5c0-69.6-49.8-129.3-118.3-141.8z"
    />
  </Svg>
);

export const AddictionIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M144.2 75.4c-.6 6.7-5.4 12.4-12 14C111.4 94.7 96 113.6 96 136c0 3.1 .3 6.2 .9 9.2c1.5 7.6-2.7 15.2-10 18C68.8 170 56 187.6 56 208c0 5.2 .8 10.3 2.4 15c2 6.1 .2 12.8-4.6 17C40.4 251.7 32 268.9 32 288c0 22.9 12 43 30.2 54.3c6.7 4.2 9.3 12.6 6.3 19.8C65.6 368.8 64 376.2 64 384c0 30.9 25.1 56 56 56c3.3 0 6.5-.3 9.5-.8c7.5-1.3 14.9 2.9 17.6 10c6.9 18 24.4 30.8 44.8 30.8c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48c-25 0-45.5 19.1-47.8 43.4zM256 480c-14.6 19.4-37.8 32-64 32c-29.6 0-55.5-16.1-69.3-40c-.9 0-1.8 0-2.7 0c-48.6 0-88-39.4-88-88c0-7.6 1-15 2.8-22C13.6 344.4 0 317.8 0 288c0-25.1 9.6-48 25.4-65c-.9-4.9-1.4-9.9-1.4-15c0-29.6 16.1-55.5 40-69.3c0-.9 0-1.8 0-2.7c0-33.6 20.7-62.4 50.1-74.2C122.3 26.4 154.1 0 192 0c26.2 0 49.4 12.6 64 32c14.6-19.4 37.8-32 64-32c37.9 0 69.7 26.4 77.9 61.8C427.3 73.6 448 102.4 448 136c0 .9 0 1.8 0 2.7c23.9 13.8 40 39.7 40 69.3c0 5.1-.5 10.1-1.4 15c15.8 17.1 25.4 40 25.4 65c0 29.8-13.6 56.4-34.8 74c1.8 7.1 2.8 14.4 2.8 22c0 48.6-39.4 88-88 88c-.9 0-1.8 0-2.7 0c-13.8 23.9-39.7 40-69.3 40c-26.2 0-49.4-12.6-64-32zm16-48c0 26.5 21.5 48 48 48c20.4 0 37.9-12.8 44.8-30.8c2.7-7.1 10.1-11.3 17.6-10c3.1 .5 6.3 .8 9.5 .8c30.9 0 56-25.1 56-56c0-7.8-1.6-15.2-4.4-21.8c-3.1-7.2-.4-15.7 6.3-19.8C468 331 480 310.9 480 288c0-19.1-8.4-36.3-21.7-48.1c-4.8-4.2-6.6-10.9-4.6-17c1.5-4.7 2.4-9.7 2.4-15c0-20.4-12.8-38-30.9-44.9c-7.3-2.8-11.5-10.4-10-18c.6-3 .9-6 .9-9.2c0-22.4-15.4-41.3-36.2-46.5c-6.6-1.7-11.4-7.3-12-14C365.5 51.1 345 32 320 32c-26.5 0-48 21.5-48 48V432z"
    />
  </Svg>
);

export const SupplimentListIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path fill="currentColor" d="M112 64c44.2 0 80 35.8 80 80V256H32V144c0-44.2 35.8-80 80-80zM32 368V288H192v80c0 44.2-35.8 80-80 80s-80-35.8-80-80zM0 144V368c0 61.9 50.1 112 112 112s112-50.1 112-112V144c0-61.9-50.1-112-112-112S0 82.1 0 144zM416 448c-70.7 0-128-57.3-128-128c0-29.6 10-56.8 26.9-78.5L494.5 421.1C472.8 438 445.6 448 416 448zM337.5 218.9C359.2 202 386.4 192 416 192c70.7 0 128 57.3 128 128c0 29.6-10 56.8-26.9 78.5L337.5 218.9zM416 480a160 160 0 1 0 0-320 160 160 0 1 0 0 320z" />
  </Svg>
);

export const MeditationIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M266.7 0h42.7c17.7 0 32 14.3 32 32V74.7H384c17.7 0 32 14.3 32 32v42.7c0 17.7-14.3 32-32 32H341.3V224c0 17.7-14.3 32-32 32H266.7c-17.7 0-32-14.3-32-32V181.3H192c-17.7 0-32-14.3-32-32V106.7c0-17.7 14.3-32 32-32h42.7V32c0-17.7 14.3-32 32-32zm0 32V90.7c0 8.8-7.2 16-16 16l-58.7 0v42.7h58.7c8.8 0 16 7.2 16 16V224h42.7V165.3c0-8.8 7.2-16 16-16l58.7 0V106.7H325.3c-8.8 0-16-7.2-16-16l0-58.7-42.7 0zM151 317.4c13.1-8.8 28.6-13.4 44.4-13.4H344c30.9 0 56 25.1 56 56c0 8.6-1.9 16.7-5.4 24h5.6l94.7-56.4c8.3-4.9 17.8-7.6 27.5-7.6h1.3c28.9 0 52.3 23.4 52.3 52.3c0 17.7-9 34.2-23.8 43.8L432.6 493.9c-18.2 11.8-39.4 18.1-61 18.1H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H371.5c15.5 0 30.6-4.5 43.6-12.9l119.6-77.8c5.8-3.7 9.2-10.2 9.2-17c0-11.2-9.1-20.3-20.3-20.3h-1.3c-3.9 0-7.7 1.1-11.1 3l-98.5 58.7c-2.5 1.5-5.3 2.3-8.2 2.3H344 320 256c-8.8 0-16-7.2-16-16s7.2-16 16-16h64 24c13.3 0 24-10.7 24-24s-10.7-24-24-24H195.4c-9.5 0-18.7 2.8-26.6 8.1L88.9 397.3l-4 2.7H80 16c-8.8 0-16-7.2-16-16s7.2-16 16-16H75.2L151 317.4z"
    />
  </Svg>
);

export const BookingSlotIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M128 96c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32V293.5c0 8.5-3.4 16.6-9.4 22.6l-58.5 58.5c-6 6-14.1 9.4-22.6 9.4H160c-17.7 0-32-14.3-32-32V96zM96 96V352c0 35.3 28.7 64 64 64H357.5c17 0 33.3-6.7 45.3-18.7l58.5-58.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM320 496c0-8.8-7.2-16-16-16H128c-53 0-96-43-96-96l0-240c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H304c8.8 0 16-7.2 16-16zM272 112c-17.7 0-32 14.3-32 32v32H208c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h32v32c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V272h32c17.7 0 32-14.3 32-32V208c0-17.7-14.3-32-32-32H336V144c0-17.7-14.3-32-32-32H272zm0 32h32v48c0 8.8 7.2 16 16 16h48v32H320c-8.8 0-16 7.2-16 16v48H272V256c0-8.8-7.2-16-16-16H208V208h48c4.2 0 8.3-1.7 11.3-4.7s4.7-7.1 4.7-11.3V144z"
    />
  </Svg>
);

export const AppointmentIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM96 432V206.7L288 37.3 480 206.7V432c0 26.5-21.5 48-48 48H144c-26.5 0-48-21.5-48-48zM272 176c-17.7 0-32 14.3-32 32v32H208c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h32v32c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V336h32c17.7 0 32-14.3 32-32V272c0-17.7-14.3-32-32-32H336V208c0-17.7-14.3-32-32-32H272zm0 32h32v48c0 8.8 7.2 16 16 16h48v32H320c-8.8 0-16 7.2-16 16v48H272V320c0-8.8-7.2-16-16-16H208V272h48c4.2 0 8.3-1.7 11.3-4.7s4.7-7.1 4.7-11.3V208z"
    />
  </Svg>
);

export const VehicleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M113.6 98.2L85.3 192.6c3.5-.4 7.1-.6 10.7-.6H416c3.6 0 7.2 .2 10.7 .6L398.4 98.2c-6.1-20.3-24.8-34.2-46-34.2H159.6c-21.2 0-39.9 13.9-46 34.2zM74.3 227.8C49.6 236.7 32 260.3 32 288v96H480V288c0-27.7-17.6-51.3-42.3-60.2l-.5 .1-.1-.3c-6.6-2.3-13.8-3.6-21.2-3.6H96c-7.4 0-14.5 1.3-21.2 3.6l-.1 .3-.5-.1zm-26-23.1l.4-1.3L83 89c10.2-33.8 41.3-57 76.6-57H352.4c35.3 0 66.5 23.2 76.6 57l34.3 114.4 .4 1.3C492.6 221.2 512 252.3 512 288v96 16 16 48c0 8.8-7.2 16-16 16s-16-7.2-16-16V416H32v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V416 400 384 288c0-35.7 19.4-66.8 48.3-83.3zM104 272a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm280 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
    />
  </Svg>
);

export const DelivaryManIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      fill="currentColor"
      d="M56 56a24 24 0 1 1 48 0A24 24 0 1 1 56 56zm80 0A56 56 0 1 0 24 56a56 56 0 1 0 112 0zM0 496c0 8.8 7.2 16 16 16s16-7.2 16-16l0-130.5L1.6 341.6c-.5-.4-1.1-.9-1.6-1.3L0 496zM60 176c7 0 13.8 1.5 20 4.2V319.4L39.1 292.2c-4.5-3-7.1-8-7.1-13.3V204c0-15.5 12.5-28 28-28zm52 38.5l37.8 58.8c5.9 9.2 16 14.7 26.9 14.7H224h16H352c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V256H176.7l-47.8-74.4C113.9 158.2 87.9 144 60 144c-33.1 0-60 26.9-60 60v74.9c0 16 8 31 21.4 39.9l100.2 66.8 22.7 113.5c1.7 8.7 10.2 14.3 18.8 12.6s14.3-10.2 12.6-18.8L153 379.4c-1.7-8.3-6.6-15.6-13.6-20.3L112 340.8V214.5zM224 256V128H352V256H240 224z"
    />
  </Svg>
);

export const RedeemCouponIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M576 64c17.7 0 32 14.3 32 32v32c-35.3 0-64-28.7-64-64h32zM224 64H512c0 53 43 96 96 96v96c-53 0-96 43-96 96H224c0-53-43-96-96-96V160c53 0 96-43 96-96zM128 288c35.3 0 64 28.7 64 64H160c-17.7 0-32-14.3-32-32V288zm416 64c0-35.3 28.7-64 64-64v32c0 17.7-14.3 32-32 32H544zM192 64c0 35.3-28.7 64-64 64V96c0-17.7 14.3-32 32-32h32zM160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM368 272a64 64 0 1 1 0-128 64 64 0 1 1 0 128zm-96-64a96 96 0 1 0 192 0 96 96 0 1 0 -192 0zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V368c0 61.9 50.1 112 112 112H528c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-44.2 0-80-35.8-80-80V112z"
    />
  </Svg>
);

export const FranchiseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M520.6 120.9l0 0c18.7 29.6 .3 66.6-29.2 70.6c-2.6 .3-5.2 .5-7.9 .5c-16.5 0-31.2-7.2-41.5-18.5c-6.1-6.7-14.7-10.5-23.7-10.5s-17.6 3.8-23.7 10.5c-10.2 11.2-24.8 18.4-41.4 18.4c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C254 184.8 239.3 192 222.7 192c-16.5 0-31.3-7.2-41.4-18.4c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7 3.8-23.7 10.5C123.6 184.8 109 192 92.4 192c-2.6 0-5.2-.2-7.9-.5c-29.3-4-47.7-41-29.1-70.6l0 0L111.6 32H464.4l56.2 88.9zM483.4 224c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-11.9c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-12c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.8 0 37.8-6.5 52.3-17.3c4.7-3.5 9-7.4 12.9-11.7c3.9 4.3 8.3 8.3 13 11.8c14.5 10.7 32.5 17.2 52.2 17.2zM80 256c-8.8 0-16 7.2-16 16v80 16 16 64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 368 352 272c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H96V272c0-8.8-7.2-16-16-16zM96 384H480v64c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384z"
    />
  </Svg>
);

export const VariantGroupIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M266.2 4.7C273 1.6 280.5 0 288 0s15 1.6 21.8 4.7l217.4 97.5c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 251.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 153.9C38.6 149.3 32 139.2 32 128s6.6-21.3 16.8-25.9L266.2 4.7zM288 32c-3 0-6 .6-8.8 1.9L69.3 128l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-210-94.1C294 32.6 291 32 288 32zM48.8 358.1l45.9-20.6 39.1 17.5L69.3 384l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 507.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 409.9C38.6 405.3 32 395.2 32 384s6.6-21.3 16.8-25.9zM94.7 209.5l39.1 17.5L69.3 256l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 379.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 281.9C38.6 277.3 32 267.2 32 256s6.6-21.3 16.8-25.9l45.9-20.6z"
    />
  </Svg>
);

export const VariantLevelIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M266.2 4.7C273 1.6 280.5 0 288 0s15 1.6 21.8 4.7l217.4 97.5c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 251.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 153.9C38.6 149.3 32 139.2 32 128s6.6-21.3 16.8-25.9L266.2 4.7zM288 32c-3 0-6 .6-8.8 1.9L69.3 128l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-210-94.1C294 32.6 291 32 288 32zM48.8 358.1l45.9-20.6 39.1 17.5L69.3 384l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 507.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 409.9C38.6 405.3 32 395.2 32 384s6.6-21.3 16.8-25.9zM94.7 209.5l39.1 17.5L69.3 256l210 94.1c2.8 1.2 5.7 1.9 8.8 1.9s6-.6 8.8-1.9l210-94.1-64.5-28.9 39.1-17.5 45.9 20.6c10.2 4.6 16.8 14.7 16.8 25.9s-6.6 21.3-16.8 25.9L309.8 379.3c-6.9 3.1-14.3 4.7-21.8 4.7s-15-1.6-21.8-4.7L48.8 281.9C38.6 277.3 32 267.2 32 256s6.6-21.3 16.8-25.9l45.9-20.6z"
    />
  </Svg>
);

export const DelivarySlotIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M192,95.7h91.2v71H160.4L192,95.7z
  M306.9,190.5h130.2v189.4H152.9V190.5h130.2H306.9z M429.6,166.8H306.9v-71H398
  L429.6,166.8L429.6,166.8z M413.4,72.1H176.6l-
  47.4,106.6v201.3v23.7h23.7h284.2h23.7v-23.7V178.6L413.4,72.1z"
    />
    <path
      fill="currentColor"
      d="M61.4,108.7c0-8.8-5.9-16-13.2-16s-13.2,7.2-
  13.2,16v256c0,61.9,41.3,112,92.3,112h342.7c7.3,0,13.2-7.2,13.2-16
  s-5.9-16-13.2-16H127.3c-36.4,0-65.9-35.8-65.9-80V108.7z"
    />
  </Svg>
);

export const DelivaryInstuctionIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zm80 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H368c8.8 0 16-7.2 16-16s-7.2-16-16-16H144zm0 96c-8.8 0-16 7.2-16 16s7.2 16 16 16H272c8.8 0 16-7.2 16-16s-7.2-16-16-16H144z" />
  </Svg>
);

export const DietionDateIcon = () => (
  <Svg
  // version="1.1"
  // id="Layer_1"
  // xmlns="http://www.w3.org/2000/svg"
  // xmlns:xlink="http://www.w3.org/1999/xlink"
  // x="0px"
  // y="0px"
  // viewBox="0 0 448 512"
  // style="enable-background:new 0 0 448 512;"
  // xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M128,16c0-8.8-7.2-16-16-
  16S96,7.2,96,16v48H64C28.7,64,0,92.7,0,128v32v32v256c0,35.3,28.7,64,64,64h320
  c35.3,0,64-28.7,64-64V192v-32v-32c0-35.3-28.7-64-64-64h-32V16c0-8.8-7.2-16-16-
  16s-16,7.2-16,16v48H128V16z M32,192h384v256
  c0,17.7-14.3,32-32,32H64c-17.7,0-32-14.3-32-32V192z 
  M64,96h320c17.7,0,32,14.3,32,32v32H32v-32C32,110.3,46.3,96,64,96z
   M87.2,236.8h34.5c1.9,0,3.4,1.6,3.4,3.4v34.5c0,1.9-1.6,3.4-3.4,3.4H87.2c-1.9,0-3.4-1.6-
  3.4-3.4v-34.5
  C83.8,238.3,85.3,236.8,87.2,236.8z 
  M70,240.2v34.5c0,9.5,7.7,17.2,17.2,17.2h34.5c9.5,0,17.2-7.7,17.2-17.2v-34.5
  c0-9.5-7.7-17.2-17.2-17.2H87.2C77.7,223,70,230.7,70,240.2z 
  M179.4,237.3h34.5c1.9,0,3.4,1.6,3.4,3.4v34.5c0,1.9-1.6,3.4-3.4,3.4
  h-34.5c-1.9,0-3.4-1.6-3.4-3.4v-34.5C176,238.9,177.6,237.3,179.4,237.3z 
  M162.2,240.8v34.5c0,9.5,7.7,17.2,17.2,17.2h34.5
  c9.5,0,17.2-7.7,17.2-17.2v-34.5c0-9.5-7.7-17.2-17.2-17.2h-
  34.5C169.9,223.5,162.2,231.3,162.2,240.8z"
    />
    <rect x="214" y="337.2" width="162.1" height="55.5" />
    <rect
      x="214"
      y="339.3"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 662.0905 
  72.0905)"
      width="162.1"
      height="55.5"
    />
  </Svg>
);
export const FranchiseListIcon = () => (
  <Svg
  // version="1.1"
  // id="Layer_1"
  // xmlns="http://www.w3.org/2000/svg"
  // xmlns:xlink="http://www.w3.org/1999/xlink"
  // x="0px"
  // y="0px"
  // viewBox="0 0 691 512"
  // style="enable-background:new 0 0 691 512;"
  // xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M520.6,120.9L520.6,120.9c18.7,29.6,0.3,66.6-29.2,70.6c-2.6,0.3-5.2,0.5-7.9,0.5c-
  16.5,0-31.2-7.2-41.5-18.5
  c-6.1-6.7-14.7-10.5-23.7-10.5s-17.6,3.8-23.7,10.5c-10.2,11.2-24.8,18.4-41.4,18.4c-
  16.5,0-31.3-7.2-41.4-18.4
  c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7,3.8-23.7,10.5C254,184.8,239.3,192,222.7,192c-
  16.5,0-31.3-7.2-41.4-18.4
  c-6.1-6.7-14.7-10.5-23.7-10.5s-17.7,3.8-23.7,10.5C123.6,184.8,109,192,92.4,192c-
  2.6,0-5.2-0.2-7.9-0.5c-29.3-4-47.7-41-29.1-70.6
  l0,0L111.6,32h352.8L520.6,120.9z M483.4,224c4.1,0,8.1-0.3,12.1-0.8c55.5-7.4,81.8-
  72.5,52.1-119.4l-57.3-90.7
  C485.2,5,476.1,0,466.4,0H109.6c-9.7,0-18.8,5-23.9,13.1l-57.4,90.7c-29.6,46.8-
  3.4,111.9,51.9,119.4c4,0.5,8.1,0.8,12.1,0.8
  c19.6,0,37.5-6.4,52-17c4.8-3.5,9.2-7.6,13.2-
  11.9c4,4.4,8.4,8.4,13.2,11.9c14.5,10.6,32.4,17,52,17s37.5-6.4,52-17
  c4.8-3.5,9.2-7.6,13.2-12c4,4.4,8.4,8.4,13.2,11.9c14.5,10.6,32.4,17,52,17c19.8,0,37.8-
  6.5,52.3-17.3c4.7-3.5,9-7.4,12.9-11.7
  c3.9,4.3,8.3,8.3,13,11.8c14.5,10.7,32.5,17.2,52.2,17.2L483.4,224z M80,256c-8.8,0-
  16,7.2-16,16v80v16v16v64c0,35.3,28.7,64,64,64
  h320c35.3,0,64-28.7,64-64v-64v-16v-16v-80c0-8.8-7.2-16-16-16s-16,7.2-
  16,16v80H96v-80C96,263.2,88.8,256,80,256z M96,384h384v64
  c0,17.7-14.3,32-32,32H128c-17.7,0-32-14.3-32-32V384z"
    />
    <path
      fill="currentColor"
      d="M639,376c-17.7,0-32,14.3-32,32s14.3,32,32,32s32-14.3,32-32S656.7,376,639,376z 
  M639,216c-17.7,0-32,14.3-32,32
  s14.3,32,32,32s32-14.3,32-32S656.7,216,639,216z M671,88c0-17.7-14.3-32-32-32s-
  32,14.3-32,32s14.3,32,32,32S671,105.7,671,88z"
    />
  </Svg>
);
export const MealPlanEntryIcon = () => (
  <Svg
  // version="1.1"
  // id="Layer_1"
  // xmlns="http://www.w3.org/2000/svg"
  // xmlns:xlink="http://www.w3.org/1999/xlink"
  // x="0px"
  // y="0px"
  // viewBox="0 0 640 512"
  // style="enable-background:new 0 0 640 512;"
  // xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M132.3,106.9l0.4,0.4c8.1,8.1,14.3,14.4,19,23.8c4.6,9.2,8.3,22.7,8.3,44.8c0,8.8,7.2,16,16,16
  s16-7.2,16-16
  c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-0.4-0.4c-8.1-8.1-14.3-14.4-
  19-23.8c-4.6-9.2-8.3-22.6-8.3-44.8
  c0-8.8-7.2-16-16-
  16S96,7.2,96,16c0,25.8,4.3,44.4,11.7,59.2C114.9,89.5,124.4,99.1,132.3,106.9z"
    />
    <path
      fill="currentColor"
      d="M228.3,106.9l0.4,0.4c8.1,8.1,14.3,14.4,19,23.8c4.6,9.2,8.3,22.7,8.3,44.8c0,8.8,7.2,16,16,16
  s16-7.2,16-16
  c0-25.8-4.3-44.4-11.7-59.2c-7.2-14.3-16.7-23.9-24.6-31.7l-0.4-0.4c-8.1-8.1-14.3-14.4-
  19-23.8c-4.6-9.2-8.3-22.6-8.3-44.8
  c0-8.8-7.2-16-16-16s-16,7.2-
  16,16c0,25.8,4.3,44.4,11.7,59.2C210.9,89.5,220.4,99.1,228.3,106.9z"
    />
    <path
      fill="currentColor"
      d="M608,256h-37.6c-6.6-18.6-24.3-32-45.3-32h-42.2l-
  0.2,32.1H525c9.2,0,16.5,7.7,16,16.9l-0.6,10.3
  c-4.2,72.1-47.8,133-109.3,162.2c-7.7,3.7-13.7,10.3-16.5,18.4c-3.3,9.4-12.2,16.1-
  22.7,16.1H248c-10.4,0-19.4-6.7-22.7-16.1
  c-2.8-8.1-8.7-14.7-16.5-18.4c-61.4-29.2-105-90.1-109.3-162.2L98.9,273c-0.5-9.2,6.8-
  16.9,16-16.9h322.7c0,0,0.7-31.7,0.7-32.1
  H114.9c-21,0-38.7,13.4-45.3,32H32c-8.8,0-16,7.2-
  16,16s7.2,16,16,16h35.8c5.9,82.9,56.5,152.7,127.3,186.4
  c7.6,21.9,28.4,37.6,52.9,37.6h144c24.5,0,45.3-15.7,52.9-37.6c70.8-33.7,121.4-
  103.5,127.3-186.4H608c8.8,0,16-7.2,16-16
  S616.8,256,608,256z"
    />
    <g>
      <path
        fill="currentColor"
        d="M567.5,176.3c-5.4,5.4-14.2,5.4-19.7,0l-74.3-74.3v198.2c0,7.7-6.2,13.9-
  13.9,13.9c-7.7,0-13.9-6.2-13.9-13.9V102.1
  l-74.4,74.4c-5.4,5.4-14.2,5.4-19.7,0c-5.4-5.4-5.4-14.2,0-19.7l98.1-98.1c5.4-
  5.4,14.2-5.4,19.7,0l98,98
  C573,162.1,573,170.9,567.5,176.3z"
      />
    </g>
  </Svg>
);
export const MealTagIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M496 64c0-17.7 14.3-32 32-32s32 14.3 32 32c0 2.7-.3 5.4-1 7.9c-1.3 5.2-.5 11.1 2.9 16c3.7 5.2 9.4 8 15.2 8.2c17.1 .6 30.9 14.7 30.9 32c0 17.7-14.3 32-32 32c-6 0-12.6-.6-20.3-1.5l-3.9-.5c-6.3-.7-13.2-1.5-19.9-2c-15.9-1-35.4-.4-51.5 11l-41.6 29.3c-18.8 13.2-30.5 32-39.1 50.6c-5.3 11.3-10 24.3-14.4 36.2c-2.5 6.7-4.8 13.1-7.1 18.8c-4.9 12.2-12.9 23.3-23.9 32.2C320 362 269.6 356.7 241.8 322.3S219.3 237.6 253.7 209.8c17.2-13.9 38.3-19.5 58.7-17.4c14.5 1.5 31.2 3.1 47.6 2c16.5-1.2 33.7-5.3 48.9-16l63.6-44.9c12.8-9 18.4-22.7 20.9-34.8C495.9 86.7 496 73.8 496 64zM528 0c-35.3 0-64 28.7-64 64c0 10.1-.2 19.7-1.9 28.1c-1.8 8.3-4.6 12.9-8.1 15.3l-40.3 28.4C370.6 110.5 325.7 96 288 96C176 96 0 224 0 384C0 512 160 512 288 512s288 0 288-128c0-60.3-25-116.1-62.2-162.2l-26.2 18.5C522 282.3 544 332 544 384c0 24.6-7.4 40.5-18.3 52c-11.6 12.3-29.6 22.1-54.5 29.2C420.4 479.7 353.4 480 288 480s-132.4-.3-183.2-14.8c-24.9-7.1-42.9-16.9-54.5-29.2C39.4 424.5 32 408.6 32 384c0-68 37.6-132 91.4-180.2C177.6 155.2 242.3 128 288 128c27.3 0 61.5 9.7 96.1 28c-7.6 3.8-16.4 5.8-26.5 6.5c-13.1 .9-27.2-.3-41.9-1.9c-28.5-3-58.2 4.9-82.2 24.3c-48.1 38.9-55.5 109.4-16.6 157.5s109.4 55.5 157.5 16.6c15.3-12.4 26.5-28 33.4-45.1c3.1-7.7 5.7-14.9 8.3-21.9l0 0c4-10.8 7.7-21.1 12.6-31.6c7.6-16.4 16.3-29.3 28.5-37.9l41.6-29.3c6.7-4.7 16.5-6.2 31-5.2c5.8 .4 11.7 1.1 18 1.8l4.3 .5c7.6 .9 15.9 1.7 23.9 1.7c35.3 0 64-28.7 64-64c0-29.8-20.4-54.9-48-62c0-.7 0-1.3 0-2c0-35.3-28.7-64-64-64z"
    />
  </Svg>
);
export const MealTimeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 0c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V32.6C388.2 40.8 480 137.7 480 256c0 123.7-100.3 224-224 224S32 379.7 32 256c0-46.6 14.2-89.9 38.6-125.8c5-7.3 3.1-17.3-4.2-22.2s-17.3-3.1-22.2 4.2C16.3 153.2 0 202.7 0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM171.3 148.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-96-96z" />
  </Svg>
);
export const OrderStatusIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM363.3 203.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z" />
  </Svg>
);
export const ExcelIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path fill="currentColor" d="M352 448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352v64h32V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V368H352v80zm-.5-288H240c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4zm123.8 52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L521.4 304H208c-8.8 0-16 7.2-16 16s7.2 16 16 16H521.4l-68.7 68.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96c6.2-6.2 6.2-16.4 0-22.6l-96-96z" />
  </Svg>
);
export const Dots = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 480a224 224 0 1 0 0-448 224 224 0 1 0 0 448zM256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM184 232l0 48-48 0 0-48 48 0zm192 0l0 48-48 0 0-48 48 0zm-144 0l48 0 0 48-48 0 0-48z" />
  </Svg>
);
export const ReloadIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M417.3 360.2c3.2-5 8.7-8.2 14.7-8.2c12.3 0 20.3 12.8 13.7 23.2C406 438.2 335.9 480 256 480C132.3 480 32 379.7 32 256S132.3 32 256 32c56.1 0 107.4 20.6 146.7 54.7L433.4 56c5.1-5.1 12.1-8 19.3-8C467.8 48 480 60.2 480 75.3V176c0 8.8-7.2 16-16 16H363.3c-15.1 0-27.3-12.2-27.3-27.3c0-7.2 2.9-14.2 8-19.3l36-36C346.5 81.1 303.3 64 256 64C150 64 64 150 64 256s86 192 192 192c67.6 0 127.1-35 161.3-87.8zM448 86.6L374.6 160H448V86.6z" />
  </Svg>
);
export const PlainTickIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z" />
  </Svg>
);
export const AddVariant = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M480 160v32.7c5.3-.5 10.6-.7 16-.7s10.7 .2 16 .7V160 128H480L32 
128H0v32V480v32H32l362.8 0c-12.9-9.1-24.5-19.9-34.6-32L32 480l0-320 448 0zM464 64L48 
64V96l416 0V64zM416 0H96V32l320 0V0zM384 368a112 112 0 1 1 224 0 112 112 0 1 1 -224 
0zm256 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM512 304V288H480v16 48H432 
416v32h16 48v48 16h32V432 384h48 16V352H560 512V304z"
    />
  </Svg>
);
export const CuisineCategoryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M170.9 83.4C187 52.8 219.1 32 256 32s69 20.8 85 51.4c2.5 4.7 7.1 7.9 12.4 8.5s10.5-1.5 13.9-5.5C379.1 72.7 396.5 64 416 64c35.3 0 64 28.7 64 64c0 4-.4 7.8-1 11.6l-.1 .5c-3.5 22.8-14.2 58.4-26.1 93.5c-11.8 34.7-24.3 67.7-31 84.7c-.4 1-1.4 1.8-2.8 1.8H373.8l25.9-141.1c1.6-8.7-4.2-17-12.8-18.6s-17 4.2-18.6 12.8l-26.4 144c-.2 1-.3 1.9-.3 2.9H272V144c0-8.8-7.2-16-16-16s-16 7.2-16 16V320H170.4c0-1-.1-1.9-.3-2.9l-26.4-144c-1.6-8.7-9.9-14.4-18.6-12.8s-14.4 9.9-12.8 18.6L138.2 320H93c-1.4 0-2.4-.8-2.8-1.8c-6.6-17-19.2-50-31-84.7C47.3 198.4 36.6 162.8 33.1 140l-.1-.5c-.7-3.7-1-7.6-1-11.6c0-35.3 28.7-64 64-64c19.5 0 36.9 8.7 48.6 22.4c3.5 4 8.7 6.1 13.9 5.5s9.9-3.8 12.4-8.5zM256 0c-41.9 0-79.1 20.1-102.4 51.2c-16-12-36-19.2-57.6-19.2C43 32 0 75 0 128c0 5.8 .5 11.5 1.5 17.1c4 25.9 15.6 63.8 27.4 98.7c12 35.3 24.7 68.8 31.4 86C65.6 343.4 78.7 352 93 352H419c14.3 0 27.4-8.6 32.6-22.2c6.7-17.2 19.4-50.7 31.4-86c11.8-34.9 23.4-72.8 27.4-98.7c1-5.5 1.5-11.2 1.5-17.1c0-53-43-96-96-96c-21.6 0-41.6 7.2-57.6 19.2C335.1 20.1 297.9 0 256 0zM64 384v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384H416v64c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384H64z"
    />
  </Svg>
);
export const IngredientManagement = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M478.2 35l0 0c-1.4-1.7-3.3-2.7-5.4-2.9c-1.2-.1-2.4 0-3.5 .4l0 0c-.7 .3-1.4 .6-2.1 1.1l-.3 .2 0 0L292.6 160H238.1L448.2 7.9c3.3-2.4 6.8-4.3 10.5-5.6c5.7-2 11.7-2.7 17.6-2.1c10.2 1.1 20 6.1 26.9 14.7c2.9 3.6 5.1 7.6 6.6 11.8c2 5.7 2.7 11.7 2.1 17.6c-1 9.7-5.6 19-13.4 25.8L398.1 160H350.1L477 46.3l.3-.3 0 0c1.5-1.3 2.4-3.1 2.7-5.2c.1-1.2 0-2.4-.4-3.5l0 0c-.3-.8-.7-1.6-1.3-2.3zM64 224v32c0 69.4 35.3 122.2 102.6 146.1c9.7 3.5 17.2 11.4 20.1 21.4s.7 20.7-5.8 28.7c-6.6 8.3-11.9 17.7-15.4 27.8H346.5c-3.6-10.1-8.8-19.5-15.4-27.8c-6.4-8.1-8.6-18.8-5.8-28.7s10.3-17.9 20.1-21.4C412.7 378.2 448 325.4 448 256V224H64zM352 480h0c.1 0 .1 0 0 0zm-192 0c-.1 0-.1 0 0 0h0zM512 208c0 8.8-7.2 16-16 16H480v32c0 71.6-32.7 130.1-93.8 163c-9.4 5-19.4 9.5-30.1 13.3c6.8 8.5 12.5 17.8 16.9 27.9c2.9 6.5 5.2 13.2 7 20.2c4.4 17.1-10.4 31.7-28.1 31.7H160c-17.7 0-32.4-14.6-28.1-31.7c1.8-7 4.1-13.7 7-20.2c4.5-10 10.2-19.4 16.9-27.9c-10.7-3.8-20.7-8.2-30.1-13.3C64.7 386.1 32 327.6 32 256V224H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H32 480h16c8.8 0 16 7.2 16 16z"
    />
  </Svg>
);
export const AddMeal = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M192 64H64V192H192V64zM64 32H192h32V64 192v32H192 64 32V192 64 32H64zM192 
  320H64V448H192V320zM64 288H192h32v32V448v32H192 64 32V448 320 288H64zM320 
  64V192H448V64H320zM288 32h32H448h32V64 192v32H448 320 288V192 64 32zM400 
  272v16 80h80 16v32H480 400v80 16H368V480 400H288 272V368h16 80V288 
  272h32z"
    />
  </Svg>
);
export const RegistrationSettings = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M0 0H16 304h16V16 359l-32 73.1V32H32V288H144h16v32H144 32v64H144h16v32H144 
32v64H267.1l-14 32H16 0V496 416 384 320 288 16 0zM344 384h20.9H432h67.1H520l8.4 
19.2 28 64L562 480l14 32H541.1h-14H336.9h-14H288l14-32 5.6-12.8 28-64L344 384zm183.1 
96l-14-32-14-32H364.9l-14 32-14 32h34.9H492.1h34.9zM352 272a80 80 0 1 1 160 0 80 80 0 1 
1 -160 0zm128 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM136 80h48 16V96v24h24 16v16 48 
16H224 200v24 16H184 136 120V224 200H96 80V184 136 120H96h24V96 80h16zm16 
56v16H136 112v16h24 16v16 24h16V184 168h16 24V152H184 168V136 
112H152v24z"
    />
  </Svg>
);
export const ActivenessStatus = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M240 64c8.8 0 16 7.2 16 16 V432c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zM208 32c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H208zM80 256c8.8 0 16 7.2 16 16 V432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16H80zM48 224c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48H48zm320-96h32c8.8 0 16 7.2 16 16V432c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16zm-48 16V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48z"
    />
  </Svg>
);
export const ReplaceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M64 96H512c17.7 0 32 14.3 32 32v69.6c10.2-4 21.1-5.9 32-5.6V128c0-35.3-28.7-64-64-64H64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H330.2l8-32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32zm48 192c-8.8 0-16 7.2-16 16s7.2 16 16 16H368c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zM96 208c0 8.8 7.2 16 16 16H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zm486.8 56l17.4 17.4c6.2 6.2 6.2 16.4 0 22.6l-24.8 24.8-40-40L560.2 264c6.2-6.2 16.4-6.2 22.6 0zM406.5 417.7L512.7 311.5l40 40L446.4 457.7c-2.1 2.1-4.6 3.5-7.4 4.2l-49 12.3 12.3-49c.7-2.8 2.2-5.4 4.2-7.4zM537.5 241.4L383.8 395.1c-6.2 6.2-10.5 13.9-12.6 22.3l-18.7 74.9c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2L446.8 493c8.4-2.1 16.1-6.5 22.3-12.6L622.8 326.6c18.7-18.7 18.7-49.1 0-67.9l-17.4-17.4c-18.7-18.7-49.1-18.7-67.9 0z"
    />
  </Svg>
);
export const TypeOfdiet = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M224 0H211.5l-3 12.1L179.5 128H16 0v32H16 288h80 16V128H368 212.5l24-96H272h16V0H272 224zM37.3 192L64 512H256V480H93.4l-24-288H37.3zm288.2 93.6c5.6-6.8 14.3-15.9 26.2-25.1c4.9-3.8 10.3-7.5 16.3-11.1c.8 8.1 7.6 14.5 15.9 14.5c8.8 0 16-7.2 16-16c0-4.6-1.9-8.7-5-11.6c15.7-6.1 33.9-10.5 54.9-11.9c-1.2 2.2-1.9 4.8-1.9 7.5c0 8.8 7.2 16 16 16s16-7.2 16-16c0-2.7-.7-5.3-1.9-7.5c21 1.4 39.2 5.9 54.9 11.9c-3.1 2.9-5 7-5 11.6c0 8.8 7.2 16 16 16c8.3 0 15.1-6.3 15.9-14.5c6 3.6 11.4 7.4 16.3 11.1c11.9 9.2 20.6 18.3 26.2 25.1c2.4 2.9 4.2 5.3 5.5 7.1v11.1H320V292.7c1.3-1.8 3.1-4.2 5.5-7.1zm312.2-6L624 287.8c13.7-8.2 13.7-8.2 13.7-8.2l0 0 0 0-.1-.1-.2-.3c-.1-.2-.3-.5-.6-.9c-.5-.7-1.2-1.8-2-3c-1.8-2.5-4.3-6-7.8-10.1c-6.9-8.2-17.2-19.1-31.3-29.9c-28.3-21.8-71.6-43.3-131.8-43.3s-103.5 21.6-131.8 43.3c-14.1 10.8-24.4 21.7-31.3 29.9c-3.4 4.1-6 7.6-7.8 10.1c-.9 1.3-1.6 2.3-2 3c-.2 .4-.4 .7-.6 .9l-.2 .3-.1 .1 0 0 0 0s0 0 13.7 8.2l-13.7-8.2-2.3 3.8v4.4 32 16h16H624h16v-16-32-4.4l-2.3-3.8zM288 359.8v32h16H624h16v-32H624 304 288zm0 56v16 64 16h16H624h16v-16-64-16H624 304 288zm32 32H608v32H320v-32z"
    />
  </Svg>
);
export const FoodManagement = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M64 9.7L273.7 234.4 251.1 257 42.9 34C38.4 49 32 77.1 32 112c0 41 12.6 72.2 36.6 104.6c21 28.3 50.1 56.9 87.3 93.2l0 0 0 0c6.6 6.4 13.4 13.1 20.5 20.1l0 0 .1 .1 .8 .8-22.6 22.6-.8-.8c-6.8-6.7-13.5-13.3-20-19.6l0 0 0 0c-37-36.2-68.3-66.8-91-97.4C15.6 198.8 0 161.1 0 112C0 69.6 8.6 36.2 13.6 20.4C17.6 7.6 29.4 0 41.7 0C50.2 0 58.2 3.5 64 9.7zM484.3 506.9L316.6 327.3l22.6-22.6L507.7 485.1c6 6.5 5.7 16.6-.8 22.6s-16.6 5.7-22.6-.8zM468.9 6.5c5.2 7.1 3.7 17.1-3.4 22.4L351.1 112.7c-3.1 2.3-6.1 4.8-8.8 7.6l-1.6 1.6C327.5 135.2 320 153.2 320 172s7.5 36.8 20.8 50.1l13.1 13.1C367.2 248.5 385.2 256 404 256s36.8-7.5 50.1-20.8l1.6-1.6c2.7-2.7 5.3-5.7 7.6-8.8l83.8-114.3c5.2-7.1 15.2-8.7 22.4-3.4s8.7 15.2 3.4 22.4L489.1 243.8c-3.3 4.4-6.9 8.6-10.7 12.5l-1.6 1.6C457.5 277.2 431.3 288 404 288c-25 0-49.1-9.1-67.8-25.5L91.3 507.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L313.5 239.8C297.1 221.1 288 197 288 172c0-27.3 10.8-53.5 30.1-72.8l1.6-1.6c3.9-3.9 8.1-7.5 12.5-10.7L446.5 3.1c7.1-5.2 17.1-3.7 22.4 3.4zM508 37.5c5.8 6.7 5.1 16.8-1.5 22.6l-128 112c-6.6 5.8-16.8 5.1-22.6-1.5s-5.1-16.8 1.5-22.6l128-112c6.7-5.8 16.8-5.1 22.6 1.5zM538.5 68c6.7 5.8 7.3 15.9 1.5 22.6l-112 128c-5.8 6.7-15.9 7.3-22.6 1.5s-7.3-15.9-1.5-22.6l112-128c5.8-6.7 15.9-7.3 22.6-1.5z"
    />
  </Svg>
);
export const Recipe = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M224 112v5.3c-18.6-6.6-32-24.4-32-45.3V66.7c18.6 6.6 32 24.4 32 45.3zM160 48V72c0 
44.2 35.8 80 80 80c8.8 0 16-7.2 16-16V112c0-44.2-35.8-80-80-80c-8.8 0-16 7.2-16 16zM48 
64c-8.8 0-16 7.2-16 16s7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H48zM16 144c-8.8 
0-16 7.2-16 16s7.2 16 16 16H144c8.8 0 16-7.2 16-16s-7.2-16-16-16H16zm16 96c0 8.8 7.2 16 
16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H48c-8.8 0-16 7.2-16 16zM336 117.3c-18.6-6.6-32-
24.4-32-45.3V66.7c18.6 6.6 32 24.4 32 45.3v5.3zM288 32c-8.8 0-16 7.2-16 16V72c0 44.2 35.8 
80 80 80c8.8 0 16-7.2 16-16V112c0-44.2-35.8-80-80-80zm160 80v5.3c-18.6-6.6-32-24.4-32-
45.3V66.7c18.6 6.6 32 24.4 32 45.3zM384 48V72c0 44.2 35.8 80 80 80c8.8 0 16-7.2 16-
16V112c0-44.2-35.8-80-80-80c-8.8 0-16 7.2-16 16zM304 253.3V248c0-20.9 13.4-38.7 32-
45.3V208c0 20.9-13.4 38.7-32 45.3zM352 168c-44.2 0-80 35.8-80 80v24c0 8.8 7.2 16 16 
16c44.2 0 80-35.8 80-80V184c0-8.8-7.2-16-16-16zM192 248c0-20.9 13.4-38.7 32-45.3V208c0 
20.9-13.4 38.7-32 45.3V248zm64-64c0-8.8-7.2-16-16-16c-44.2 0-80 35.8-80 80v24c0 8.8 7.2 
16 16 16c44.2 0 80-35.8 80-80V184zm160 69.3V248c0-20.9 13.4-38.7 32-45.3V208c0 20.9-
13.4 38.7-32 45.3zM464 168c-44.2 0-80 35.8-80 80v24c0 8.8 7.2 16 16 16c44.2 0 80-35.8 80-
80V184c0-8.8-7.2-16-16-16zM129.8 441.1c-5.5-9.5-15.5-15.6-26.5-16C64 423.6 32.5 391.5 32 
352H480c-.5 39.5-32 71.6-71.3 73.1c-11 .4-21 6.5-26.5 16c-2.4 4.2-6.9 6.9-11.9 6.9H141.7c-5 
0-9.5-2.7-11.9-6.9zM31 320c-17.1 0-31 13.9-31 31c0 57.3 45.3 103.9 102.1 106.1c7.9 13.7 
22.7 22.9 39.6 22.9H370.3c17 0 31.8-9.2 39.6-22.9C466.7 454.9 512 408.2 512 351c0-17.1-
13.9-31-31-31H31z"
    />
  </Svg>
);
export const Order = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path fill="currentColor" d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16H53.9c7.6 0 14.2 5.3 15.7 12.8l58.9 288c6.1 29.8 32.3 51.2 62.7 51.2H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H191.2c-15.2 0-28.3-10.7-31.4-25.6L152 288H466.5c29.4 0 55-20 62.1-48.5L570.6 71.8c5-20.2-10.2-39.8-31-39.8H99.1C92.5 13 74.4 0 53.9 0H16zm90.1 64H539.5L497.6 231.8C494 246 481.2 256 466.5 256H145.4L106.1 64zM168 456a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm80 0a56 56 0 1 0 -112 0 56 56 0 1 0 112 0zm200-24a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112z" />
  </Svg>
);
export const OrderList = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M48 112V80H80v32H48zM16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40C26.7 48 16 58.7 16 72zm160 8c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zm0 160c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zm0 160c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H176zM48 240H80v32H48V240zm-8-32c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40zm8 224V400H80v32H48zM16 392v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24z"
    />
  </Svg>
);
export const DeliveryStatus = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path fill="currentColor" d="M440 0c-48.6 0-88 39.4-88 88V373.8L17.7 464.8C4.9 468.3-2.6 481.5 .8 494.3s16.7 20.3 29.5 16.8L416.2 406c3.1 59.1 52 106 111.8 106c61.9 0 112-50.1 112-112s-50.1-112-112-112c-45.3 0-84.3 26.9-102 65.6l-.4 .1-25.7 7V88c0-22.1 17.9-40 40-40H616c13.3 0 24-10.7 24-24s-10.7-24-24-24H440zm88 336a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
  </Svg>
);
export const DeliverySlot = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112V256c0 5.3 2.7 10.3 7.1 13.3l96 64c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L272 247.4V112c0-8.8-7.2-16-16-16s-16 7.2-16 16z" />
  </Svg>
);
export const DiscountType = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M32 242.7l192 192 160-160V237.3L210.7 64H32V242.7zM224 32L416 224v64L246.6 457.4 224 480l-22.6-22.6L0 256V64 32H32 224zM88 144a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM355.3 44.7L505.6 194.9l4.7 4.7v6.6 99.5 6.6l-4.7 4.7L355.3 467.3 344 478.6 321.4 456l11.3-11.3L478.2 299.1V212.9L332.7 67.3 321.4 56 344 33.4l11.3 11.3z" />
  </Svg>
);
export const Package = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M261.5 35.1c-3.6-1.3-7.5-1.3-11 0L44.1 110.7 256 194l211.9-83.2L261.5 35.1zM32 385.7c0 6.7 4.2 12.7 10.5 15L240 473.1V222L32 140.3V385.7zm240 87.4l197.5-72.4c6.3-2.3 10.5-8.3 10.5-15V140.3L272 222v251zM239.5 5c10.7-3.9 22.4-3.9 33 0l208 76.3c18.9 6.9 31.5 24.9 31.5 45.1V385.7c0 20.1-12.6 38.1-31.5 45.1L272.5 507c-10.7 3.9-22.4 3.9-33 0l-208-76.3C12.6 423.8 0 405.8 0 385.7V126.4c0-20.1 12.6-38.1 31.5-45.1L239.5 5z" />
  </Svg>
);
export const PackageManagement = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M256 32c53 0 96 43 96 96c0 8.8 7.2 16 16 16s16-7.2 16-16C384 57.3 326.7 0 256 0S128 57.3 128 128c0 8.8 7.2 16 16 16s16-7.2 16-16c0-53 43-96 96-96zM80.4 161.5c-14.1-1.6-27.5 6.2-33.1 19.2l-32 74.7C5.4 278.2 18.7 304.4 43 310l159.2 36.7c18.9 4.4 38.6-3.1 49.9-18.9L320 232.9l67.9 95.1c11.3 15.8 31 23.2 49.9 18.9L597 310c24.3-5.6 37.6-31.8 27.8-54.7l-32-74.7c-5.6-13-19-20.8-33.1-19.2L320 189.2 80.4 161.5zm-3.7 31.8l214.5 24.8-65.1 91.2c-3.8 5.3-10.3 7.7-16.6 6.3L50.2 278.9c-4.9-1.1-7.5-6.4-5.6-10.9l32-74.7zM544 348.7v61.8c0 7.3-5 13.7-12.1 15.5L336 475V336c0-8.8-7.2-16-16-16s-16 7.2-16 16V475L108.1 426C101 424.3 96 417.9 96 410.5V348.7l-32-9.1v71c0 22 15 41.2 36.4 46.6l208 52c7.6 1.9 15.6 1.9 23.3 0l208-52c21.4-5.3 36.4-24.5 36.4-46.6v-71l-32 9.1zM348.8 218.1l214.5-24.8 32 74.7c2 4.6-.7 9.8-5.6 10.9L430.6 315.6c-6.3 1.5-12.9-1-16.6-6.3l-65.1-91.2zm146.4-181c2.8-8.4-1.7-17.4-10.1-20.2s-17.4 1.7-20.2 10.1l-32 96c-2.8 8.4 1.7 17.4 10.1 20.2s17.4-1.7 20.2-10.1l32-96z"
    />
  </Svg>
);
export const Blog = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M32 96V416H608V96H32zM0 64H32 608h32V96 416v32H608 32 0V416 96 64zm96 
  96v64H320V160H96zM64 128H96 320h32v32 64 32H320 96 64V224 160 128zm0 224H80 
  336h16v32H336 80 64V352zm320 0h16H560h16v32H560 400 384V352zM96 
  288v32H64V288H96zm32 0h32v32H128V288zm96 0v32H192V288h32zm32 
  0h32v32H256V288zm96 0v32H320V288h32z"
    />
  </Svg>
);
export const Blogcategory = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M0 512L0 0 32 0l0 512L0 512zM160 192l320 0 0-96L160 96l0 96zM128 96l0-32 32 0 320 0 
  32 0 0 32 0 96 0 32-32 0-320 0-32 0 0-32 0-96zm32 320l192 0 0-96-192 0 0 96zm-32-96l0-32 
  32 0 192 0 32 0 0 32 0 96 0 32-32 0-192 0-32 0 0-32 0-96z"
    />
  </Svg>
);
export const SocialMedia = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M288.8 81.7c3.5-12.8 16.7-20.3 29.5-16.8s20.3 16.7 16.8 29.5l-4.5 16.4c-5.5 20.2-13.9 39.3-24.7 56.9c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2H448c17.7 0 32 14.3 32 32c0 11.3-5.9 21.3-14.8 27c-7.2 4.6-9.5 13.9-5.3 21.3c2.6 4.6 4.1 10 4.1 15.7c0 12.4-7 23.1-17.3 28.5c-4.2 2.2-7.3 6.1-8.3 10.8s.1 9.5 3 13.2c4.2 5.4 6.7 12.2 6.7 19.5c0 14.2-9.2 26.3-22.1 30.4c-7.8 2.6-12.4 10.6-10.7 18.6c.5 2.2 .7 4.5 .7 6.9c0 17.7-14.3 32-32 32H294.5c-15.8 0-31.2-4.7-44.4-13.4l-38.5-25.7c-9-6-16.6-13.7-22.4-22.6c-4.9-7.4-14.8-9.4-22.2-4.6s-9.4 14.8-4.6 22.2c8.1 12.3 18.7 23.1 31.4 31.6l38.5 25.7c18.4 12.3 40 18.8 62.1 18.8H384c35.3 0 64-28.7 64-64l0-.6c19.1-11.1 32-31.7 32-55.4c0-8.7-1.8-17.1-4.9-24.7C487.9 323.6 496 306.8 496 288c0-6.5-1-12.8-2.8-18.7C504.8 257.7 512 241.7 512 224c0-35.3-28.7-64-64-64H346.4c6.2-13.1 11.3-26.7 15.1-40.9l4.5-16.4c8.1-29.8-9.5-60.6-39.3-68.8s-60.6 9.5-68.8 39.3l-4.5 16.4c-8.9 32.6-29.6 60.8-58.2 79l-3.1 2c-11.8 7.5-21.7 17.1-29.5 28.2c-5.1 7.2-3.3 17.2 4 22.3s17.2 3.3 22.3-4c5.4-7.7 12.2-14.4 20.4-19.5l3.1-2c35.3-22.4 60.9-57.2 71.9-97.5l4.5-16.4zM32 224H96V448H32V224zM0 224V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"
    />
  </Svg>
);
export const SocialPlugins = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path fill="currentColor" d="M207.6 51.6c2-8.6-3.4-17.2-12-19.2s-17.2 3.4-19.2 12l-23 99.6H48c-8.8 0-16 7.2-16 16s7.2 16 16 16h98L109.1 336H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h85.7L80.4 460.4c-2 8.6 3.4 17.2 12 19.2s17.2-3.4 19.2-12l23-99.6H261.7l-21.3 92.4c-2 8.6 3.4 17.2 12 19.2s17.2-3.4 19.2-12l23-99.6H400c8.8 0 16-7.2 16-16s-7.2-16-16-16H302l36.9-160H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H346.3l21.3-92.4c2-8.6-3.4-17.2-12-19.2s-17.2 3.4-19.2 12l-23 99.6H186.3l21.3-92.4zM178.9 176H306L269.1 336H142l36.9-160z" />
  </Svg>
);

export const Banner = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M512 96c17.7 0 32 14.3 32 32V384c0 6.1-1.7 11.8-4.6 16.6L377 197.6c-12.8-16-37.2-16-50
  0L244.4 300.9l-27.3-37.5c-12.3-17-37.4-17.7-50.6-1.4L45.3 410C37.3 404.2 32 394.7 32 
  384V128c0-17.7 14.3-32 32-32H512zm-1.3 320H193.3l63-78.8L352 217.6 510.7 416zM160.1 
  448H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64C28.7 64 0 92.7 0 128V384c0 
  35.3 28.7 64 64 64h95.9c.1 0 .2 0 .3 0zm-7.8-32H81.8L191.3 282.2l32.4 44.6L152.3 416zM144
  160a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm0 64a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
    />
  </Svg>
);

export const BannerType = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M128 0V64h32V0H128zM320 0V64h32V0H320zM224 0H192V64h32V0zM400 0V288h32V0H400zM512 0H480V288h32V0zM320 128V480H32V128H320zM32 96H0v32V480v32H32 320h32V480 128 96H320 32zm64 96H256V416H96V192zM64 160v32V416v32H96 256h32V416 192 160H256 96 64z" />
  </Svg>
);
export const DashboardIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M48 64c-8.8 0-16 7.2-16 16V240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H48zM0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48V240c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80zM304 256c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H304zm-48 16c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V272zM144 352H48c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16zM48 320h96c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48zM304 64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16H304zM256 80c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-26.5 0-48-21.5-48-48V80z"
    />
  </Svg>
);
export const AdmissionHistory = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M480 192v.7c5.3-.5 10.6-.7 16-.7s10.7 .2 16 .7V192c0-35.3-28.7-64-64-64L64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64l330.8 0c-12.9-9.1-24.5-19.9-34.6-32L64 480c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32zM448 64L64 64c-8.8 0-16 7.2-16 16s7.2 16 16 16l384 0c8.8 0 16-7.2 16-16s-7.2-16-16-16zM400 0H112c-8.8 0-16 7.2-16 16s7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16zM384 368a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm256 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM512 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V304z"
    />
  </Svg>
);
export const EnlargeIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M292.7 196.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V160c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H352c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L292.7 196.7zM219.3 315.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L32 457.4V352c0-8.8-7.2-16-16-16s-16 7.2-16 16V496c0 8.8 7.2 16 16 16H160c8.8 0 16-7.2 16-16s-7.2-16-16-16H54.6L219.3 315.3z" />
  </Svg>
);
export const PrintIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M96 160H64V64C64 28.7 92.7 0 128 0H357.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 18.7 28.3 18.7 45.3V160H416V90.5c0-8.5-3.4-16.6-9.4-22.6L380.1 41.4c-6-6-14.1-9.4-22.6-9.4H128c-17.7 0-32 14.3-32 32v96zm352 64H64c-17.7 0-32 14.3-32 32V384H64V352c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32v32h32V256c0-17.7-14.3-32-32-32zm0 192v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V416H32c-17.7 0-32-14.3-32-32V256c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V384c0 17.7-14.3 32-32 32H448zM96 352l0 128H416V352H96zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
  </Svg>
);
export const InfoIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
  </Svg>
);
export const RestoreIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M164.2 39.5L148.9 64H299.1L283.8 39.5c-2.9-4.7-8.1-7.5-13.6-7.5H177.7c-5.5 0-10.6 2.8-13.6 7.5zM311 22.6L336.9 64H384h32 16c8.8 0 16 7.2 16 16s-7.2 16-16 16H416V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V96H16C7.2 96 0 88.8 0 80s7.2-16 16-16H32 64h47.1L137 22.6C145.8 8.5 161.2 0 177.7 0h92.5c16.6 0 31.9 8.5 40.7 22.6zM64 96V432c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V96H64zm155.9 93.3c5.9 6.6 5.4 16.7-1.2 22.6l-49 44.1H240c44.2 0 80 35.8 80 80v40c0 8.8-7.2 16-16 16s-16-7.2-16-16V336c0-26.5-21.5-48-48-48H169.7l49 44.1c6.6 5.9 7.1 16 1.2 22.6s-16 7.1-22.6 1.2l-80-72c-3.4-3-5.3-7.4-5.3-11.9s1.9-8.9 5.3-11.9l80-72c6.6-5.9 16.7-5.4 22.6 1.2z"
    />
  </Svg>
);

export const ErrorIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M128 416v48c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V416h96zm-16 96c12.3 0 23.5-4.6 32-12.2c8.5 7.6 19.7 12.2 32 12.2h64c26.5 0 48-21.5 48-48V416 400 384 193.8l51.4 198.1 4 15.5 18 69.2c6.6 25.5 32 40.6 56.7 33.8l59.6-16.5c24.7-6.8 39.3-33 32.7-58.5l-13.9-53.7-4-15.5-63.9-246-4-15.5-18-69.2C400 9.9 374.6-5.2 349.9 1.6L290.3 18.1c-3.5 1-6.8 2.3-9.9 4C271.9 8.8 257 0 240 0H176c-12.3 0-23.5 4.6-32 12.2C135.5 4.6 124.3 0 112 0H48C21.5 0 0 21.5 0 48V96v16 16V384v16 16 48c0 26.5 21.5 48 48 48h64zM288 64.8V63.5c.3-7.2 5.1-13 10.8-14.6l59.6-16.5c6.6-1.8 14.8 2 17.2 11l14.1 54.2-87.3 24.2L288.6 68.6c-.3-1.3-.5-2.6-.6-3.8zm-32-.6c0 0 0 0 0 0V96H160V48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16V64.2zM176 480c-8.8 0-16-7.2-16-16V416h96v48c0 8.8-7.2 16-16 16H176zM128 128V384H32V128h96zm0-80V96H32V48c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16zM256 384H160V128h96V384zM397.7 128.7l59.8 230.5-87.3 24.2L310.4 152.8l87.3-24.2zm67.9 261.5l13.8 53.2c2.4 9.4-3.2 17.7-10.3 19.6l-59.6 16.5c-6.6 1.8-14.8-2-17.2-11l-14.1-54.2 87.3-24.2z"
    />
  </Svg>
);

export const HospitalIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M160 64c0-35.3 28.7-64 64-64H416c35.3 0 64 28.7 64 64V96h96c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H416v0H352 288 224v0H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h96V64zM480 336V480h96c17.7 0 32-14.3 32-32V320H528c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V224H528c-8.8 0-16-7.2-16-16s7.2-16 16-16h80V160c0-17.7-14.3-32-32-32H480V336zM160 128H64c-17.7 0-32 14.3-32 32v32h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H32v64h80c8.8 0 16 7.2 16 16s-7.2 16-16 16H32V448c0 17.7 14.3 32 32 32h96V128zM448 336V64c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V480h64V416c0-35.3 28.7-64 64-64s64 28.7 64 64v64h64V336zm-96 80c0-17.7-14.3-32-32-32s-32 14.3-32 32v64h64V416zM304 64h32c13.3 0 24 10.7 24 24v16h16c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H360v16c0 13.3-10.7 24-24 24H304c-13.3 0-24-10.7-24-24V184H264c-13.3 0-24-10.7-24-24V128c0-13.3 10.7-24 24-24h16V88c0-13.3 10.7-24 24-24zm8 56c0 8.8-7.2 16-16 16H272v16h24c8.8 0 16 7.2 16 16v24h16V168c0-8.8 7.2-16 16-16h24V136H344c-8.8 0-16-7.2-16-16V96H312v24z"
    />
  </Svg>
);
export const PackagingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M592 32H304c-8.8 0-16 7.2-16 16v80H256V48c0-26.5 21.5-48 48-48H592c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H381.3c1.8-5 2.7-10.4 2.7-16V480H592c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16zM555.3 316.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L496 302.6V432c0 8.8-7.2 16-16 16s-16-7.2-16-16V302.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l64-64c6.2-6.2 16.4-6.2 22.6 0l64 64zM352 192H32l0 64H64 320h32V192zM0 256V192c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32V480c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32l0-192c-17.7 0-32-14.3-32-32zm64 32V480H320V288H64zm80 32h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
    />
  </Svg>
);
export const BirthdayIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M365.1 32c-17.6 0-33.9 9-43.2 23.9l-45 72.1H288h80c26.5 0 48-21.5 48-48s-21.5-48-48-48h-2.9zm-130 96l-45-72.1C180.8 41 164.5 32 146.9 32H144c-26.5 0-48 21.5-48 48s21.5 48 48 48h80 11.1zM256 101l38.8-62c15.2-24.2 41.7-39 70.3-39H368c44.2 0 80 35.8 80 80c0 18-6 34.6-16 48h32c26.5 0 48 21.5 48 48v64c0 20.9-13.4 38.7-32 45.3V288 448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V288v-2.7C13.4 278.7 0 260.9 0 240V176c0-26.5 21.5-48 48-48H80C70 114.6 64 98 64 80C64 35.8 99.8 0 144 0h2.9c28.6 0 55.2 14.7 70.3 39L256 101zM144 160H48c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16H64 240V160H224 144zm128 0v96H448h16c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H368 288 272zM240 288H64V448c0 17.7 14.3 32 32 32H240V288zm32 192H416c17.7 0 32-14.3 32-32V288H272V480z"
    />
  </Svg>
);

export const AnniversaryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M172.8 342.4l0 0 0 0 0 0L160 352l-12.8-9.6 0 0 0 0 0 0 0 0-.1-.1-.8-.6c-.8-.6-2-1.6-3.6-2.8c-3.1-2.6-7.8-6.4-13.3-11.4c-11.2-10-26.1-24.5-40.9-42C58 249.1 32 204.5 32 160C32 89.3 89.3 32 160 32s128 57.3 128 128c0 44.5-26 89.1-56.5 125.4c-14.8 17.6-29.7 32-40.9 42c-5.6 5-10.2 8.8-13.3 11.4c-1.6 1.3-2.8 2.2-3.6 2.8l-.8 .6-.1 .1 0 0zm-9.4 39.9L180.2 416H139.8l16.8-33.7 3.4-6.8 3.4 6.8zM192 368s128-96 128-208C320 71.6 248.4 0 160 0S0 71.6 0 160C0 272 128 368 128 368L97.4 429.3c-.9 1.8-1.4 3.8-1.4 5.8c0 7.1 5.8 12.9 12.9 12.9H211.1c7.1 0 12.9-5.8 12.9-12.9c0-2-.5-4-1.4-5.8L192 368zm139.2-84.9C362.3 367.8 448 432 448 432l-30.6 61.3c-.9 1.8-1.4 3.8-1.4 5.8c0 7.1 5.8 12.9 12.9 12.9H531.1c7.1 0 12.9-5.8 12.9-12.9c0-2-.5-4-1.4-5.8L512 432s128-96 128-208c0-88.4-71.6-160-160-160c-46.1 0-87.7 19.5-116.9 50.8c3.2 14.6 4.9 29.7 4.9 45.2c0 .7 0 1.3 0 2c21.8-39.4 63.8-66 112-66c70.7 0 128 57.3 128 128c0 44.5-26 89.1-56.5 125.4c-14.8 17.6-29.7 32-40.9 42c-5.6 5-10.2 8.8-13.3 11.4c-1.6 1.3-2.8 2.2-3.6 2.8l-.8 .6-.1 .1 0 0 0 0 0 0 0 0L480 416l-12.8-9.6 0 0 0 0 0 0 0 0-.1-.1-.8-.6c-.8-.6-2-1.6-3.6-2.8c-3.1-2.6-7.8-6.4-13.3-11.4c-11.2-10-26.1-24.5-40.9-42C381.2 317 357.6 278 352.9 238.3c-6.1 16-13.6 31-21.7 44.7zM480 439.6l3.4 6.8L500.2 480H459.8l16.8-33.7 3.4-6.8zM112 160c0-26.5 21.5-48 48-48c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16s16-7.2 16-16zm320 64c0-26.5 21.5-48 48-48c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16s16-7.2 16-16z"
    />
  </Svg>
);

export const AnnouncementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
    <path fill="currentColor" d="M560 32c-8.8 0-16 7.2-16 16V59.5L32 187.5V176c0-8.8-7.2-16-16-16s-16 7.2-16 16v19.5V208v96 12.5V336c0 8.8 7.2 16 16 16s16-7.2 16-16V324.5l131.7 32.9c-2.4 8.4-3.7 17.4-3.7 26.6c0 53 43 96 96 96c46.2 0 84.7-32.6 93.9-76L544 452.5V464c0 8.8 7.2 16 16 16s16-7.2 16-16V440 72 48c0-8.8-7.2-16-16-16zM544 419.5L32 291.5v-71l512-128v327zM192 384c0-6.5 1-12.9 2.8-18.8l124 31C313.1 425.7 287.2 448 256 448c-35.3 0-64-28.7-64-64z" />
  </Svg>
);

export const DietpriceIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M80 32C35.8 32 0 67.8 0 112V400c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V176c0-44.2-35.8-80-80-80H112c-8.8 0-16 7.2-16 16s7.2 16 16 16H432c26.5 0 48 21.5 48 48V400c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48H464c8.8 0 16-7.2 16-16s-7.2-16-16-16H80zM384 312a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
  </Svg>
);
export const AvailableCalories = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
    <path fill="currentColor" d="M352 320c0-18.5-8.9-46-26.5-80.1c-17-33-40-68.3-63.5-101c-23.4-32.6-47-62.1-64.7-83.4c-1.9-2.2-3.6-4.4-5.4-6.4c-1.7 2.1-3.5 4.2-5.4 6.4c-17.7 21.3-41.2 50.8-64.7 83.4c-23.5 32.7-46.5 68-63.5 101C40.9 274 32 301.5 32 320c0 88.4 71.6 160 160 160s160-71.6 160-160zM0 320C0 235.8 121 83.6 170.9 24.4C183.8 9.1 192 0 192 0s8.2 9.1 21.1 24.4C263 83.6 384 235.8 384 320c0 106-86 192-192 192S0 426 0 320zm192 80v32c-61.9 0-112-50.1-112-112h32c0 44.2 35.8 80 80 80z" />
  </Svg>
);
export const CloneIcon = () => (
  <Svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M64 480H288c17.7 0 32-14.3 32-32V384h32v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h64v32H64c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32zM224 320H448c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V288c0 17.7 14.3 32 32 32zm-64-32V64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64z" />{" "}
  </Svg>
);
export const OpenBook = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M213.3 128H416V64L213.3 64l-32 32 32 32zM190.6 41.4c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3zM64 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm0 160a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM32 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm181.3 32H416V384H213.3l-32 32 32 32zm-22.6-86.6c6-6 14.1-9.4 22.6-9.4H416c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6l43.3-43.3zM181.3 256l32 32H480V224l-266.7 0-32 32zm-33.9-11.3l43.3-43.3c6-6 14.1-9.4 22.6-9.4H480c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H213.3c-8.5 0-16.6-3.4-22.6-9.4l-43.3-43.3c-6.2-6.2-6.2-16.4 0-22.6z"
    />
  </Svg>
);
export const PauseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-80V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
  </Svg>
);
export const PlayIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
    <path fill="currentColor" d="M56.3 66.3c-4.9-3-11.1-3.1-16.2-.3s-8.2 8.2-8.2 14V432c0 5.8 3.1 11.1 8.2 14s11.2 2.7 16.2-.3l288-176c4.8-2.9 7.7-8.1 7.7-13.7s-2.9-10.7-7.7-13.7l-288-176zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z" />
  </Svg>
);
export const SwapIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M427.3 36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 112H400 344c-20.1 0-39.1 9.5-51.2 25.6L244 202.7l20 26.7 54.4-72.5c6-8.1 15.5-12.8 25.6-12.8h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80zM124.8 361.6c-3 4-7.8 6.4-12.8 6.4H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c15.1 0 29.3-7.1 38.4-19.2L204 309.3l-20-26.7-59.2 78.9zM404.7 475.3c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 368H400 344c-10.1 0-19.6-4.7-25.6-12.8l-168-224C141.3 119.1 127.1 112 112 112H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c5 0 9.8 2.4 12.8 6.4l168 224C304.9 390.5 323.9 400 344 400h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6z"
    />
  </Svg>
);
export const RedoIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M417.3 360.2c3.2-5 8.7-8.2 14.7-8.2c12.3 0 20.3 12.8 13.7 23.2C406 438.2 335.9 480 256 480C132.3 480 32 379.7 32 256S132.3 32 256 32c56.1 0 107.4 20.6 146.7 54.7L433.4 56c5.1-5.1 12.1-8 19.3-8C467.8 48 480 60.2 480 75.3V176c0 8.8-7.2 16-16 16H363.3c-15.1 0-27.3-12.2-27.3-27.3c0-7.2 2.9-14.2 8-19.3l36-36C346.5 81.1 303.3 64 256 64C150 64 64 150 64 256s86 192 192 192c67.6 0 127.1-35 161.3-87.8zM448 86.6L374.6 160H448V86.6z" />
  </Svg>
);
export const PreparingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M112 0c-8.8 0-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16c0-8.8-7.2-16-16-16zM256 352H192c-88.4 0-160-71.6-160-160H416c0 88.4-71.6 160-160 160zM32 160c-17.7 0-32 14.3-32 32c0 80.5 49.5 149.4 119.7 177.9l-7.4 16.2c-5.2-1.4-10.7-2.1-16.4-2.1c-35.3 0-64 28.7-64 64s28.7 64 64 64c29.8 0 54.9-20.4 62-48H335.9l17.6 38.6c3.7 8 13.1 11.6 21.2 7.9s11.6-13.1 7.9-21.2L328.3 369.9C398.5 341.4 448 272.5 448 192c0-17.7-14.3-32-32-32H32zM140.4 401.9l10.2-22.4c13.4 2.9 27.2 4.5 41.5 4.5h64c14.2 0 28.1-1.5 41.5-4.5L321.3 432H158c-3-11.7-9.2-22-17.6-30.1zM64 448a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM224 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v4c0 17.6 8.3 34.2 22.4 44.8l32 24c6 4.5 9.6 11.6 9.6 19.2v4c0 8.8 7.2 16 16 16s16-7.2 16-16v-4c0-17.6-8.3-34.2-22.4-44.8l-32-24c-6-4.5-9.6-11.6-9.6-19.2V16z"
    />
  </Svg>
);
export const DeliveryIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M64 32C46.3 32 32 46.3 32 64V352c0 17.7 14.3 32 32 32h5.5c13.2-37.3 48.7-64 90.5-64s77.4 26.7 90.5 64H384V64c0-17.7-14.3-32-32-32H64zm0 384c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H352c35.3 0 64 28.7 64 64V96h65.3c14 0 27.3 6.1 36.4 16.8l78.7 91.8c7.5 8.7 11.6 19.8 11.6 31.2V384h16c8.8 0 16 7.2 16 16s-7.2 16-16 16H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96zM416 224H570.9l-77.5-90.4c-3-3.5-7.5-5.6-12.1-5.6H416v96zm0 32v88.4c17-15.2 39.4-24.4 64-24.4c41.8 0 77.4 26.7 90.5 64H576V256H416zM224 416A64 64 0 1 0 96 416a64 64 0 1 0 128 0zm256 64a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"
    />
  </Svg>
);
export const NoteIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path fill="currentColor" d="M128 96c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32V256H368c-26.5 0-48 21.5-48 48v80H160c-17.7 0-32-14.3-32-32V96zM446.2 288c-1.6 4.5-4.2 8.7-7.6 12.1l-74.5 74.5c-3.4 3.4-7.6 6-12.1 7.6V304c0-8.8 7.2-16 16-16h78.2zM96 96V352c0 35.3 28.7 64 64 64H341.5c17 0 33.3-6.7 45.3-18.7l74.5-74.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM320 496c0-8.8-7.2-16-16-16H128c-53 0-96-43-96-96l0-240c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H304c8.8 0 16-7.2 16-16z" />
  </Svg>
);
export const DeliveryAddressIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path
      fill="currentColor"
      d="M56 56a24 24 0 1 1 48 0A24 24 0 1 1 56 56zm80 0A56 56 0 1 0 24 56a56 56 0 1 0 112 0zM0 496c0 8.8 7.2 16 16 16s16-7.2 16-16l0-130.5L1.6 341.6c-.5-.4-1.1-.9-1.6-1.3L0 496zM60 176c7 0 13.8 1.5 20 4.2V319.4L39.1 292.2c-4.5-3-7.1-8-7.1-13.3V204c0-15.5 12.5-28 28-28zm52 38.5l37.8 58.8c5.9 9.2 16 14.7 26.9 14.7H224h16H352c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H224c-17.7 0-32 14.3-32 32V256H176.7l-47.8-74.4C113.9 158.2 87.9 144 60 144c-33.1 0-60 26.9-60 60v74.9c0 16 8 31 21.4 39.9l100.2 66.8 22.7 113.5c1.7 8.7 10.2 14.3 18.8 12.6s14.3-10.2 12.6-18.8L153 379.4c-1.7-8.3-6.6-15.6-13.6-20.3L112 340.8V214.5zM224 256V128H352V256H240 224z"
    />
  </Svg>
);
export const AddFileIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M160 384c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H304v80c0 17.7 14.3 32 32 32h80c0 .3 0 .5 0 .8V352c0 17.7-14.3 32-32 32H160zM336 57.5L390 112H336V57.5zM160 0C124.7 0 96 28.7 96 64V352c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V144.8c0-12.7-5-24.8-13.9-33.8l-96-96.8C329.1 5.1 316.8 0 304 0H160zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112z" />
  </Svg>
);
export const MeasurementIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M112 176a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm320 0c0-28.8-6.9-56-19.2-80H448c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V128c0-17.7 14.3-32 32-32H99.2C86.9 120 80 147.2 80 176c0 97.2 78.8 176 176 176s176-78.8 176-176zM391.8 64C359.5 24.9 310.7 0 256 0S152.5 24.9 120.2 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H391.8zM320 224c0-20.6-9.7-39-24.9-50.7l23.6-55c3.5-8.1-.3-17.5-8.4-21s-17.5 .3-21 8.4l-23.6 55c-3.2-.5-6.4-.7-9.7-.7c-35.3 0-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64zm-64-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
    />
  </Svg>
);
export const DietaryRecord = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M320 32H64C46.3 32 32 46.3 32 64V448c0 17.7 14.3 32 32 32H296.2c9.8 11.8 21 22.3 33.5 31.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V198.6c-11.2 3.2-21.9 7.4-32 12.6V64c0-17.7-14.3-32-32-32zM64 144c0-8.8 7.2-16 16-16H304c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm16 80H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 96H208c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm464 48a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zm-256 0a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-32a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm-32 96c0-8.8 7.2-16 16-16V384c-8.8 0-16-7.2-16-16s7.2-16 16-16h16c8.8 0 16 7.2 16 16v48h0c8.8 0 16 7.2 16 16s-7.2 16-16 16H432 416c-8.8 0-16-7.2-16-16z"
    />
  </Svg>
);
export const DownloadIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M272 16c0-8.8-7.2-16-16-16s-16 7.2-16 16V329.4L139.3 228.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l128 128c6.2 6.2 16.4 6.2 22.6 0l128-128c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L272 329.4V16zM140.1 320H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H371.9l-32 32H448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H172.1l-32-32zM432 416a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
  </Svg>
);
export const Password = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M287.2 328.4c15.4 4.9 31.8 7.6 48.8 7.6c88.4 0 160-71.6 160-160s-71.6-160-160-160s-160 71.6-160 160c0 17.1 2.7 33.5 7.6 48.8c1.8 5.7 .3 12-3.9 16.2L18.3 402.3c-1.5 1.5-2.3 3.5-2.3 5.7v80c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V448c0-8.8 7.2-16 16-16h40c4.4 0 8-3.6 8-8V384c0-8.8 7.2-16 16-16h40c2.1 0 4.2-.8 5.7-2.3L271 332.3c4.2-4.2 10.5-5.8 16.2-3.9zM336 352c-18.7 0-36.8-2.9-53.7-8.3L249 377c-4.5 4.5-10.6 7-17 7H208 192v16 24c0 13.3-10.7 24-24 24H144 128v16 24c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V408c0-6.4 2.5-12.5 7-17L168.3 229.7c-5.4-16.9-8.3-35-8.3-53.7C160 78.8 238.8 0 336 0s176 78.8 176 176s-78.8 176-176 176zm32-248a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm24 40a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"
    />
  </Svg>
);
const EmailIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor" d="M64 96c-17.7 0-32 14.3-32 32l0 39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9l0-39.9c0-17.7-14.3-32-32-32L64 96zM32 207.6L32 384c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-176.4L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
  </Svg>
);

export const GetIcon = ({ icon }) => {
  const trimmedIcon = icon && typeof icon === "string" ? icon.trim() : "";

  switch (trimmedIcon) {
    case "preparation":
      return <PreparingIcon />;
    case "delivery":
      return <DeliveryIcon />;
    case "packaging":
      return <PackagingIcon />;
    case "download":
      return <DownloadIcon />;
    case "delete":
      return <DeleteIcon />;
    case "swap":
      return <SwapIcon />;
    case "clone":
      return <CloneIcon />;
    case "play":
      return <PlayIcon />;
    case "pause":
      return <PauseIcon />;
    case "info":
      return <InfoIcon />;
    case "add":
      return <AddIcon />;
    case "edit":
      return <EditIcon />;
    case "home":
      return <HomeIcon />;
    case "filter":
      return <FilterIcon />;
    case "time":
      return <DurationIcon />;
    case "manager":
      return <UserIcon />;
    case "availableCalories":
    case "proteinCategory":
      return <AvailableCalories />;
    case "timings":
      return <DurationIcon />;
    case "call":
      return <CallIcon />;
    case "note":
      return <NoteIcon />;
    case "attendanceToday":
      return <DurationIcon />;
    case "search":
      return <SearchIcon />;
    case "down":
      return <DownIcon />;
    case "password":
      return <Password />;
    case "email":
      return <EmailIcon />;
    case "up":
      return <UpIcon />;
    case "menu":
      return <MenuIcon />;
    case "mealSettings":
      return <MealSettingsIcon />;
    case "settings":
      return <SettingsIcon />;
    case "weeklyPlanner":
      return <WeeklyPlannerIcon />;
    case "booking":
      return <BookingIcon />;
    case "dispatch":
      return <DispatchIcon />;
    case "deliveryMan":
      return <DeliveryManIcon />;
    case "dietitian":
      return <DieticianIcon />;
    case "subscriber":
    case "subscribersList":
      return <SubscribersIcon />;
    case "userRole":
    case "user":
      return <UserRoleIcon />;
    case "Close":
    case "close":
      return <CloseIcon />;
    case "userList":
      return <UserListIcon />;
    case "aimOfProgram":
      return <AimOfprogrmeIcon />;
    case "medicalCondition":
      return <MedicalConditionIcon />;
    case "proteinCategory1":
      return <ProtineCatagoryIcon />;
    case "dayOfweek":
      return <DayOfweekIcon />;
    case "foodLikeList":
      return <LikeListIcon />;
    case "dislikeList":
      return <DislikeListIcon />;
    case "addiction":
      return <AddictionIcon />;
    case "supplement":
      return <SupplimentListIcon />;
    case "medication":
      return <MeditationIcon />;
    case "bookingSlot":
      return <BookingSlotIcon />;
    case "appointment":
      return <AppointmentIcon />;
    case "vehicle":
      return <VehicleIcon />;
    case "redeenCoupon":
      return <RedeemCouponIcon />;
    case "franchise":
      return <FranchiseIcon />;
    case "variantGroup":
      return <VariantGroupIcon />;
    case "variantLevel":
      return <VariantLevelIcon />;
    case "deliverySlot":
      return <DeliverySlot />;
    case "delivaryInstuction":
      return <DelivaryInstuctionIcon />;
    case "dietionDate":
      return <DietionDateIcon />;
    case "dateIcon":
      return <DateIcon />;
    case "franchiseList":
      return <FranchiseListIcon />;
    case "mealPlanEntry":
      return <MealPlanEntryIcon />;
    case "mealTag":
      return <MealTagIcon />;
    case "mealTime":
    case "mealTimeCategory":
    case "mealManagement":
      return <MealTimeIcon />;
    case "orderStatus":
      return <OrderStatusIcon />;
    case "dots":
      return <Dots />;
    case "excel":
      return <ExcelIcon />;
    case "reload":
      return <ReloadIcon />;
    case "checked":
      return <PlainTickIcon />;
    case "open":
      return <OpneIcon />;
    case "addVarient":
      return <AddVariant />;
    case "cuisineCategory":
      return <CuisineCategoryIcon />;
    case "recipe":
    case "recepe":
    case "ingredientManagement":
      return <IngredientManagement />;
    case "addMeal":
      return <AddMeal />;
    case "registrationSettings":
      return <RegistrationSettings />;
    case "activeAdmission":
    case "activenessStatus":
      return <ActivenessStatus />;
    // TYPE OF DIET IS A DIET //
    case "typeOfDiet":
      return <TypeOfdiet />;
    case "foodManagement":
      return <FoodManagement />;
    case "order":
      return <Order />;
    case "deliveryStatus":
      return <DeliveryStatus />;
    case "discountType":
      return <DiscountType />;
    case "package":
      return <Package />;
    case "packageManagement":
      return <PackageManagement />;
    case "blog":
      return <Blog />;
    case "orderList":
      return <OrderList />;
    case "blogCategory":
      return <Blogcategory />;
    case "banner":
      return <Banner />;
    case "bannerType":
      return <BannerType />;
    case "notification":
      return <NotificationIcon />;
    case "socialMedia":
      return <SocialMedia />;
    case "socialPlugins":
      return <SocialPlugins />;
    case "patient":
      return <PatientIcon />;
    case "admissionHistory":
      return <AdmissionHistory />;
    case "enlarge":
      return <EnlargeIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "print":
      return <PrintIcon />;
    case "replace":
      return <ReplaceIcon />;
    case "hospital":
      return <HospitalIcon />;
    case "redo":
      return <RedoIcon />;
    case "restoreIcon":
    case "restore":
      return <RestoreIcon />;
    case "errorlog":
      return <ErrorIcon />;
    case "birthday":
      return <BirthdayIcon />;
    case "anniversary":
      return <AnniversaryIcon />;
    case "announcement":
      return <AnnouncementIcon />;
    case "dietprice":
      return <DietpriceIcon />;
    case "previous":
      return <PreviousIcon />;
    case "next":
      return <NextIcon />;
    case "open-book":
      return <OpenBook />;
    case "delivery-address":
      return <DeliveryAddressIcon />;
    case "add-file":
      return <AddFileIcon />;
    case "measurement":
      return <MeasurementIcon />;
    case "dietary-record":
      return <DietaryRecord />;
    default:
      // console.log("icons from project", icon);
      return <GetCustomIcon icon={icon} />;
  }
};
